import { createFileRoute, useNavigate } from '@tanstack/react-router';
import useOrganizationMemberCares from '@/hooks/membercare/useOrganizationMemberCares';
import LoadingPage from '@/components/templates/LoadingPage';
import { Card, HStack, Text, VStack } from '@chakra-ui/react';
import CustomButton from '@/components/atoms/CustomButton';
import SendEmailModalWithTrigger from '@/modules/user/SendEmailModalWithTrigger';
import Calendly from '@/components/molecules/Calendly';
import { useCallback, useState } from 'react';
import useTelemetry from '@/providers/TelemetryProvider/useTelemetry';
import { mixpanelEvents } from '@/constants/mixpanel';
import colors from '@/theme/colors';
import SvgIconCalendar from '@/components/atoms/SvgIconCalendar';
import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import { TMemberCare } from '@/types/MemberCare.types';
import LoadingLogo from '@/components/atoms/LoadingLogo';
import logoVariants from '@/assets/img/logo';
import CardHeaderWithImageAndName from '@/components/atoms/CardHeaderWithImageAndName';
import { BOBBI_MC_GRAW_NAME, ROB_JAMES_NAME } from '@/constants';

const CARD_WIDTH = '295px';
const CARD_HEIGHT = '400px';

const ACTION_BUTTON_WIDTH = '120px';

const NAME_LENGTH_TO_PUSH_ABOUT_ME = 25;

const MemberCareDirectory = () => {
  const navigate = useNavigate();
  const { trackEvent } = useTelemetry();

  const { isLoading, data, fetchNextPage, isFetchingNextPage, hasNextPage } = useOrganizationMemberCares();

  // logic if we need to reset the MemberCares query - i.e. make it start from page 1 every time we come to the page
  // useEffect(() => {
  //   return () => {
  //     queryClient.removeQueries({ queryKey: [queryKeys.memberCare.myOrganizationMemberCares], exact: false });
  //   };
  // }, []);

  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);

  const renderMemberCareCard = useCallback(
    (memberCare: TMemberCare) => {
      const memberCareName = `${memberCare.firstName} ${memberCare.lastName} `;

      /* PROD: current solution for label addition for only Bobby and Rob; if we need labels in the future, BE will add the appropriate field */
      const memberCareSubtitle = (() => {
        if (memberCareName.includes(BOBBI_MC_GRAW_NAME)) return 'Member Care Specialist';
        if (memberCareName.includes(ROB_JAMES_NAME)) return 'Member Care Director';
      })();

      const noOfLinesInAboutMe = (() => {
        let noOfLines = memberCareName.length > NAME_LENGTH_TO_PUSH_ABOUT_ME ? 9 : 11;
        if (memberCareSubtitle) {
          noOfLines -= 1;
        }
        return noOfLines;
      })();

      return (
        <Card
          key={memberCare.user_id}
          size="sm"
          width={CARD_WIDTH}
          height={CARD_HEIGHT}
          variant={'elevated'}
          borderRadius={'8px'}
          padding={'20px'}
          shadow={'0px 0px 20px 0px rgba(0, 65, 121, 0.15)'}
        >
          <CardHeaderWithImageAndName
            onClick={() => navigate({ to: '/member-care/$memberCareId', params: { memberCareId: memberCare.user_id } })}
            hasTooltip
            profilePicture={memberCare.profilePicture}
            name={memberCareName}
            nameSubtitle={memberCareSubtitle}
          />

          <VStack height={'100%'} justify={'space-between'}>
            {/* About Me, ex Bio */}
            <VStack marginTop={'5px'} align={'start'} gap={0} width={'100%'}>
              <Text variant={'urbanistExtraBoldSmall'} color={'text.mediumGray'}>
                ABOUT ME
              </Text>
              <Text
                height={'100%'}
                width={'100%'}
                whiteSpace={'pre-wrap'}
                variant={'urbanistSemiBoldRegular'}
                color={'text.mediumBlue'}
                noOfLines={noOfLinesInAboutMe}
              >
                {memberCare.bio ?? 'No About Me'}
              </Text>
            </VStack>

            {/* Action Buttons */}
            <HStack width={'100%'} justifyContent={'space-between'}>
              {memberCare.calendly_link ? (
                <VStack gap={0}>
                  <Calendly isOpen={isCalendlyOpen} setIsOpen={setIsCalendlyOpen} url={memberCare.calendly_link} />
                  <CustomButton
                    isTransparent
                    label="Schedule"
                    hoverBackgroundColor="primary.500"
                    labelColor="primary.500"
                    borderHoverColor="primary.500"
                    onClick={() => {
                      setIsCalendlyOpen(true);
                      trackEvent(mixpanelEvents.MEMBER_CARE_CALL_SCHEDULED);
                    }}
                    width={ACTION_BUTTON_WIDTH}
                    labelHoverColor="white"
                    iconBeforeLabelJSX={<SvgIconCalendar color={colors.primary[500]} size={18} />}
                    iconBeforeLabelHoveredJSX={<SvgIconCalendar color={colors.extra.white} size={18} />}
                    disabled={memberCare?.calendly_link === ''}
                  />
                </VStack>
              ) : (
                <Text variant={'urbanistSemiBoldRegular'} color={'text.mediumBlue'} paddingX={'10px'}>
                  No Calendly Link
                </Text>
              )}

              <SendEmailModalWithTrigger
                toEmail={memberCare.email}
                toEmailName={`${memberCare.firstName} ${memberCare.lastName}`}
                triggerButtonLabel="Email"
                triggerButtonWidth={ACTION_BUTTON_WIDTH}
                backgroundColor={colors.extra.white}
                noShadow
              />
            </HStack>
          </VStack>
        </Card>
      );
    },
    [isCalendlyOpen],
  );

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <VStack width={MAX_CONTAINER_WIDTH} paddingBottom={'40px'}>
      <Text variant={'loraTitle'}>Member Care Team</Text>
      <HStack gap={'20px'} width={'100%'} wrap={'wrap'} marginTop={'110px'} rowGap={'80px'}>
        {data?.map((memberCare) => renderMemberCareCard(memberCare))}
      </HStack>
      {hasNextPage && !isFetchingNextPage && (
        <HStack cursor={'pointer'} onClick={() => fetchNextPage()} marginY={'30px'}>
          <Text
            variant={'urbanistSemiBoldSmallTitle'}
            cursor={'pointer'}
            _hover={{ fontWeight: 700 }}
            color={'primary.500'}
            fontSize="18px"
          >
            View More
          </Text>
        </HStack>
      )}

      {isFetchingNextPage && (
        <HStack paddingY={'20px'}>
          <LoadingLogo logoVariant={logoVariants.purpleNoText160x160} logoWidth="54px" logoHeight="54px" />
        </HStack>
      )}
    </VStack>
  );
};

export const Route = createFileRoute('/_authenticated/_member-care/member-care/directory')({
  component: MemberCareDirectory,
});
