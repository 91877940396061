import React from 'react';
import { Box, Center, Stack, Text, Input, VStack, HStack, Checkbox, Button } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import HeroBackgroundPage from '@/components/templates/HeroBackgroundPage';
import MyOmniaInfoIllustrations from '@/components/templates/MyOmniaInfoIllustrations';
import onboardingMessages from '@/messages/onboarding';
import EulaLinkedText from '@/components/molecules/EulaLinkedText';
import getUserAgentInfo from '@/utils/getUserAgentInfo';
import { HEADER_HEIGHT } from '@/constants/dimensions';
import { TEulaAcceptance, TRepresentativeFormData } from './types';
import useToast from '@/hooks/useToast';
import { useOrganizationOnboardingStore } from '@/store/useOrganizationOnboardingStore';
import { useAppStore } from '@/store/useAppStore';
import useTelemetry from '@/providers/TelemetryProvider/useTelemetry';
import { mixpanelEvents } from '@/constants/mixpanel';
import { EMAIL_REGEX } from '@/constants/stringVars';

type Props = {
  onSubmit?: (formData: TRepresentativeFormData, eulaAcceptance: TEulaAcceptance | null) => void;
};

const schema = yup
  .object()
  .shape({
    name: yup.string().required('Name is required.'),
    website: yup.string(),
    eula: yup.boolean().oneOf([true], 'Please accept the EULA'),
    representativeFullName: yup.string(),
    representativePhoneNumber: yup.string(),
    representativeEmail: yup.string().matches(EMAIL_REGEX, 'Wrong email format.').required('Email is required.'),
  })
  .required();

const RepresentativeForm = ({ onSubmit = () => {} }: Props) => {
  const toast = useToast();
  const { organization, isEulaAccepted } = useAppStore();
  const { representativeForm, eulaAcceptance } = useOrganizationOnboardingStore();
  const { trackEvent } = useTelemetry();

  const [eulaAcceptedAt, setEulaAcceptedAt] = React.useState<number | null>(eulaAcceptance?.timestamp ?? null);

  const {
    register,
    formState: { isValid, errors, touchedFields },
    handleSubmit,
    watch,
  } = useForm<TRepresentativeFormData>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: representativeForm?.name || organization?.name || '',
      website: representativeForm?.website || organization?.website || '',
      representativeFullName: representativeForm?.representativeFullName || organization?.representativeFullName || '',
      representativePhoneNumber:
        representativeForm?.representativePhoneNumber || organization?.representativePhoneNumber || '',
      representativeEmail: representativeForm?.representativeEmail || organization?.representativeEmail || '',
      isEulaAccepted: isEulaAccepted || representativeForm?.isEulaAccepted || false,
    },
  });

  const onEulaCheckboxCapture = () => {
    const nowUtcTimestamp = new Date().getTime();
    setEulaAcceptedAt(nowUtcTimestamp);

    const userAgentString = navigator.userAgent;
    const { operating_system, browser } = getUserAgentInfo(userAgentString);

    trackEvent(mixpanelEvents.EULA_ACCEPTED, { nowUtcTimestamp, operating_system, browser });
  };

  const isEulaAcceptanceChecked = watch('isEulaAccepted');
  const isFormValid = (isValid && isEulaAcceptanceChecked) || (isValid && isEulaAccepted);

  const onFormSubmit = (data: TRepresentativeFormData) => {
    const userAgentString = navigator.userAgent;
    const { operating_system, browser } = getUserAgentInfo(userAgentString);

    if (eulaAcceptedAt === null && !isEulaAccepted) {
      toast({
        title: 'EULA Acceptance Required',
        description: onboardingMessages.eulaNotAcceptedProperly,
        status: 'error',
        isClosable: true,
      });
      return;
    }

    if (eulaAcceptedAt === null) {
      onSubmit(data, null);
      return;
    }

    onSubmit(data, { timestamp: eulaAcceptedAt, operatingSystem: operating_system, browser: browser });
  };

  return (
    <HeroBackgroundPage
      title={onboardingMessages.userOnboardingTitle}
      heroChildrenTopMargin={HEADER_HEIGHT}
      heroChildren={
        <Stack alignItems={'center'}>
          <Text
            variant={'urbanistSemiBold'}
            color={'text.veryLightBlue'}
            opacity={0.7}
            position={'relative'}
            marginTop={'16px'}
            maxWidth={'400px'}
          >
            {onboardingMessages.organizationOnboardingDescription}
          </Text>
          <Box maxW={{ md: '295px' }} minW={{ md: '295px' }} marginTop={'24px'}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <Stack>
                <Stack>
                  <Input
                    {...register('name', { required: true })}
                    className="custom-input"
                    placeholder={'Organization Name'}
                    _placeholder={{ fontWeight: '600', color: 'text.mediumGray' }}
                    data-state={touchedFields.name ? (errors.name?.message ? 'invalid' : 'valid') : ''}
                  />
                  <Text variant={'error'} textAlign={'left'}>
                    {errors.name?.message ?? ''}
                  </Text>
                </Stack>
                <Stack>
                  <Input
                    {...register('website', { required: true })}
                    className="custom-input"
                    placeholder={'Organization Website'}
                    _placeholder={{ fontWeight: '600', color: 'text.mediumGray' }}
                    data-state={touchedFields.website ? (errors.website?.message ? 'invalid' : 'valid') : ''}
                  />
                  <Text variant={'error'} textAlign={'left'}>
                    {errors.website?.message ?? ''}
                  </Text>
                </Stack>
                <Stack>
                  <Input
                    {...register('representativeFullName', { required: true })}
                    className="custom-input"
                    placeholder={"Representative's Full Name"}
                    _placeholder={{ fontWeight: '600', color: 'text.mediumGray' }}
                    data-state={
                      touchedFields.representativeFullName
                        ? errors.representativeFullName?.message
                          ? 'invalid'
                          : 'valid'
                        : ''
                    }
                  />
                  <Text variant={'error'} textAlign={'left'}>
                    {errors.representativeFullName?.message ?? ''}
                  </Text>
                </Stack>
                <Stack>
                  <Input
                    {...register('representativePhoneNumber', { required: true })}
                    className="custom-input"
                    placeholder={"Representative's Phone Number"}
                    _placeholder={{ fontWeight: '600', color: 'text.mediumGray' }}
                    data-state={
                      touchedFields.representativePhoneNumber
                        ? errors.representativePhoneNumber?.message
                          ? 'invalid'
                          : 'valid'
                        : ''
                    }
                  />
                  <Text variant={'error'} textAlign={'left'}>
                    {errors.representativePhoneNumber?.message ?? ''}
                  </Text>
                </Stack>
                <Stack>
                  <Input
                    {...register('representativeEmail', { required: true })}
                    className="custom-input"
                    placeholder={"Representative's Email"}
                    _placeholder={{ fontWeight: '600', color: 'text.mediumGray' }}
                    data-state={
                      touchedFields.representativeEmail
                        ? errors.representativeEmail?.message
                          ? 'invalid'
                          : 'valid'
                        : ''
                    }
                  />
                  <Text variant={'error'} textAlign={'left'}>
                    {errors.representativeEmail?.message ?? ''}
                  </Text>
                </Stack>
                {!isEulaAccepted && (
                  <VStack paddingX={'4px'}>
                    <HStack>
                      <HStack width={'24px'}>
                        <Checkbox
                          {...register('isEulaAccepted', { required: true, onChange: onEulaCheckboxCapture })}
                          colorScheme="white"
                          backgroundColor={'white'}
                          borderColor={'white'}
                          borderRadius={'4px'}
                          width={'22px'}
                          height={'22px'}
                          iconColor="text.mediumGray"
                          justifyContent={'center'}
                          alignItems={'center'}
                        />
                      </HStack>
                      <EulaLinkedText />
                    </HStack>
                    <Text variant={'error'} textAlign={'left'}>
                      {errors.isEulaAccepted?.message ?? ''}
                    </Text>
                  </VStack>
                )}
                <Button
                  type="submit"
                  variant="formSubmit"
                  width="full"
                  isDisabled={!isFormValid}
                  disabled={!isFormValid}
                  marginTop={'10px'}
                >
                  {onboardingMessages.proceed}
                </Button>
              </Stack>
            </form>
          </Box>
        </Stack>
      }
    >
      <Center marginY={'100px'}>
        <MyOmniaInfoIllustrations variant="giving_to_your_employees" />
      </Center>
    </HeroBackgroundPage>
  );
};

export default RepresentativeForm;
