import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay } from '@chakra-ui/react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onCloseComplete?: () => void;
  children: React.ReactNode;
  footerContent?: React.ReactNode;
  isBlur?: boolean;
  width?: string;
  height?: string;
  backgroundColor?: string;
  closeButtonColor?: string;
  closeOnOverlayClick?: boolean;
  closeButtonHidden?: boolean;
  scrollOverflow?: boolean;
};

const ModalContainer = ({
  children,
  footerContent = null,
  isOpen,
  onClose,
  onCloseComplete = () => {},
  isBlur = true,
  width = '100%',
  height,
  backgroundColor = 'transparent',
  closeButtonColor = 'black',
  closeOnOverlayClick = true,
  closeButtonHidden = false,
  scrollOverflow = true,
}: Props) => {
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      onCloseComplete={onCloseComplete}
      isCentered
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <ModalOverlay bg="blackAlpha.700" backdropFilter={isBlur ? 'blur(18px)' : ''} />
      <ModalContent
        borderRadius={'20px'}
        backgroundColor={backgroundColor}
        minWidth={width}
        minHeight={height}
        maxHeight={'90vh'}
        overflowY={scrollOverflow ? 'scroll' : 'initial'}
      >
        {!closeButtonHidden && (
          <ModalCloseButton
            size={'lg'}
            width={'2.2rem'}
            height={'2.2rem'}
            marginTop={'15px'}
            marginRight={'10px'}
            color={closeButtonColor}
          />
        )}
        <ModalBody>{children}</ModalBody>
        {footerContent && <ModalFooter>{footerContent}</ModalFooter>}
      </ModalContent>
    </Modal>
  );
};

export default ModalContainer;
