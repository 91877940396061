import { useEffect, useLayoutEffect } from 'react';
import { useUserOnboardingStore } from '@/store/useUserOnboardingStore';
import { createFileRoute, redirect } from '@tanstack/react-router';
import ContactInfoForm from '@/modules/onboarding/user/A_ContactInfoForm';
import DemographicsSetup from '@/modules/onboarding/user/B_DemographicsSetup';
import ProfileOverview from '@/modules/onboarding/user/C_ProfileOverview';
import { useAppStore } from '@/store/useAppStore';
import useToast from '@/hooks/useToast';
import { mixpanelEvents } from '@/constants/mixpanel';
import useTelemetry from '@/providers/TelemetryProvider/useTelemetry';

// 0 - ContactInfoForm (Contact Info)
// 1 - Demographics (Demographics Info)
// 2 - ProfileOverview (Picture upload)

const OnboardingUser = () => {
  const toast = useToast();
  const { trackEvent } = useTelemetry();

  const {
    onboardingStatuses: { isUserContactInfoCompleted, isUserDemographicsCompleted },
    user,
    setUser,
  } = useAppStore();
  const { step, setStep } = useUserOnboardingStore(); // nextStep, prevStep

  const isContactInfoForm = step === 0;
  const isDemographicsSetup = step === 1;

  const onboardingStoreUnSubscribe = useUserOnboardingStore.subscribe((state) => state.step);

  useLayoutEffect(() => {
    const isOnboardingCompleted = isUserContactInfoCompleted && isUserDemographicsCompleted;
    if (isOnboardingCompleted) {
      // navigate({
      //   to: '/onboarding/wholeness-screener'
      // })
      setStep(2);

      return;
    }

    setStep(isUserContactInfoCompleted ? 1 : 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      onboardingStoreUnSubscribe();
    };
  }, [onboardingStoreUnSubscribe]);

  if (isContactInfoForm) {
    return (
      <ContactInfoForm
        onSubmitSuccess={() => {
          if (isUserDemographicsCompleted) {
            toast({
              title: 'Contact info has been successfully updated',
              description: 'You can now start using the platform',
              status: 'info',
            });

            trackEvent(mixpanelEvents.USER_SIGN_UP_DONE, user?.user_metadata);
          }
          if (!isUserDemographicsCompleted) {
            toast({
              title: 'Contact info has been successfully updated',
              description: 'You will be redirected to setup your demographics info',
              status: 'info',
            });
            setStep(1);
            return;
          }

          setStep(3);
        }}
      />
    );
  }

  if (isDemographicsSetup) {
    return (
      <DemographicsSetup
        onSubmitSuccess={(updatedUser) => {
          setUser(updatedUser);
          trackEvent(mixpanelEvents.USER_DEMOGRAPHICS_ONBOARDING_DONE, updatedUser.user_metadata);
          setStep(3);
        }}
      />
    );
  }

  return <ProfileOverview setOnboardingStep={setStep} />;
};

export const Route = createFileRoute('/_authenticated/_onboarding/onboarding/user')({
  component: OnboardingUser,
  beforeLoad: async ({ context: { auth } }) => {
    const isOrgAdmin = auth?.isAdmin;
    const {
      onboardingStatuses: { isOrganizationOnboardingCompleted },
    } = useAppStore.getState();

    const shouldRedirectToOrgOnboarding = isOrgAdmin && !isOrganizationOnboardingCompleted;
    if (shouldRedirectToOrgOnboarding) {
      throw redirect({
        to: '/onboarding/organization',
      });
    }
  },
});
