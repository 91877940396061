import { Card, Center, HStack, Image, Spinner, Text, Tooltip, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import CustomButton from '@/components/atoms/CustomButton';
import Calendly from '@/components/molecules/Calendly';
import useTelemetry from '@/providers/TelemetryProvider/useTelemetry';
import { mixpanelEvents } from '@/constants/mixpanel';
import iconsPng from '@/assets/img/png/icons';
import SvgIcon from '@/components/atoms/SvgIcon';
import svgIcons from '@/assets/svg/icons';
import { useNavigate } from '@tanstack/react-router';
import sharedRoutes from '@/constants/routes/shared';
import { TSupportTeamMember } from '@/types/User.types';
import colors from '@/theme/colors';
import SvgIconCalendar from '@/components/atoms/SvgIconCalendar';
import SendEmailModalWithTrigger from './SendEmailModalWithTrigger';

const CARD_WIDTH = '380px';
const CARD_HEIGHT = '480px';

const ACTION_BUTTON_WIDTH = '160px';

interface Props {
  data: TSupportTeamMember[];
  isLoading?: boolean;
}

const UserSupportTeamSection = ({ data, isLoading }: Props) => {
  const navigate = useNavigate();
  const { trackEvent } = useTelemetry();

  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);

  if (isLoading) {
    return (
      <VStack width={'100%'} height={CARD_HEIGHT} justify={'center'} alignItems={'center'}>
        <Spinner />
      </VStack>
    );
  }

  if (!data || data.length === 0) {
    return (
      <VStack width={'100%'} height={CARD_HEIGHT} justify={'center'} alignItems={'center'}>
        <Card
          size="sm"
          minWidth={'370px'}
          minHeight={'215px'}
          variant={'elevated'}
          borderRadius={'20px'}
          padding={'30px'}
          shadow={'0px 0px 20px 0px rgba(0, 65, 121, 0.15)'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <VStack position={'absolute'} top={-50}>
            <Image alt="notepad" src={iconsPng.notepad} width={100} height={100} />
          </VStack>
          <Text
            variant={'lora'}
            fontSize={'22px'}
            lineHeight={'22px'}
            fontWeight={500}
            color={'text.mediumBlue'}
            fontStyle={'italic'}
            marginTop={'50px'}
          >
            No support team members assigned?
          </Text>

          <CustomButton
            label="Contact us"
            style={{ marginTop: '40px' }}
            backgroundColor="primary.500"
            labelColor="extra.white"
            iconBeforeLabelJSX={<SvgIcon iconPath={svgIcons.email} size={19} color={'white'} />}
            onClick={() => {
              navigate({ to: sharedRoutes.contactSupport });
            }}
          />
        </Card>
      </VStack>
    );
  }

  return (
    <HStack width={'100%'} minH={'500px'} gap={'40px'}>
      {data.map((supportTeamMember) => (
        <VStack key={supportTeamMember.id} width={'100%'} alignItems={'center'} paddingY={'18px'}>
          <Card
            size="sm"
            width={CARD_WIDTH}
            height={CARD_HEIGHT}
            variant={'elevated'}
            borderRadius={'8px'}
            padding={'20px'}
            shadow={'0px 0px 20px 0px rgba(0, 65, 121, 0.15)'}
          >
            <Tooltip
              label={'Click to view profile'}
              padding={'14px'}
              borderRadius={'6px'}
              color={'text.darkBlue'}
              backgroundColor={'extra.white'}
              boxShadow={'0px 2px 8px 0px #00417933'}
              placement="right"
              right={55}
              top={-2}
            >
              <HStack
                width="100%"
                paddingBottom={'20px'}
                gap={'20px'}
                cursor={'pointer'}
                data-group
                onClick={() => {
                  if (supportTeamMember.type === 'member-care') {
                    navigate({
                      to: '/member-care/$memberCareId',
                      params: { memberCareId: supportTeamMember.id },
                    });
                  }
                  if (supportTeamMember.type === 'peer-support') {
                    navigate({
                      to: '/peer-support/$peerSupportId',
                      params: { peerSupportId: supportTeamMember.id },
                    });
                  }
                }}
              >
                {/* Support pic */}
                <VStack
                  maxWidth={'100px'}
                  maxHeight={'100px'}
                  borderWidth={'4px'}
                  borderRadius={'full'}
                  borderColor={'white'}
                  shadow={'0px 6px 24px 0px rgba(14, 0, 98, 0.2)'}
                  overflow={'hidden'}
                  cursor={'pointer'}
                  _groupHover={{ boxShadow: '0px 4px 8px 0px rgba(14, 0, 98, 0.2)' }}
                  transition={'box-shadow 0.1s'}
                >
                  {supportTeamMember.profileImage ? (
                    <Image
                      src={supportTeamMember.profileImage}
                      alt={supportTeamMember.name}
                      width={100}
                      height={100}
                      borderRadius={'full'}
                      objectFit={'cover'}
                    />
                  ) : (
                    <Center backgroundColor={'#EDE8FD'} height={100} width={100}>
                      <SvgIcon
                        color={'#fff'}
                        iconPath={svgIcons.userSeeThrough}
                        size={60}
                        boxHeight={80}
                        boxWidth={80}
                      />
                    </Center>
                  )}
                </VStack>

                {/* Support role and name */}
                <VStack align={'start'}>
                  <Text variant={'urbanistBold'} color={'text.mediumGray'}>
                    {supportTeamMember.type === 'member-care' ? 'Member Care' : 'Peer Support'}
                  </Text>

                  <Text
                    variant={'lora'}
                    fontSize={'22px'}
                    lineHeight={'22px'}
                    fontWeight={500}
                    color={'text.darkBlue'}
                    fontStyle={'italic'}
                  >
                    {supportTeamMember.name}
                  </Text>
                </VStack>
              </HStack>
            </Tooltip>

            {/* Action Buttons */}
            <HStack width={'100%'} justifyContent={'space-between'}>
              {supportTeamMember.type === 'member-care' ? (
                supportTeamMember.calendlyLink ? (
                  <VStack gap={0}>
                    <Calendly
                      isOpen={isCalendlyOpen}
                      setIsOpen={setIsCalendlyOpen}
                      url={supportTeamMember.calendlyLink}
                    />
                    <CustomButton
                      isTransparent
                      label="Schedule Call"
                      backgroundColor="primary.500"
                      labelColor="primary.500"
                      onClick={() => {
                        setIsCalendlyOpen(true);
                        trackEvent(mixpanelEvents.MEMBER_CARE_CALL_SCHEDULED);
                      }}
                      width={ACTION_BUTTON_WIDTH}
                      style={{ border: `2px solid ${colors.primary[500]}` }}
                      labelHoverColor="white"
                      iconBeforeLabelJSX={<SvgIconCalendar color={colors.primary[500]} size={18} />}
                      iconBeforeLabelHoveredJSX={<SvgIconCalendar color={colors.extra.white} size={18} />}
                      disabled={supportTeamMember.calendlyLink === ''}
                    />
                  </VStack>
                ) : (
                  <Text variant={'urbanistSemiBoldRegular'} color={'text.mediumBlue'} paddingX={'5px'}>
                    No Calendly Link added
                  </Text>
                )
              ) : null}
              {supportTeamMember.type === 'peer-support' && (
                <SendEmailModalWithTrigger
                  toEmail={supportTeamMember.email}
                  toEmailName={supportTeamMember.name}
                  triggerButtonLabel="Email"
                  triggerButtonWidth={ACTION_BUTTON_WIDTH}
                  triggerButtonTransparent={true}
                />
              )}
              <CustomButton
                isTransparent
                label={supportTeamMember.type === 'member-care' ? 'View More' : 'More Peers'}
                onClick={() => navigate({ to: `/${supportTeamMember.type}/directory` })}
                backgroundColor={colors.text.mediumBlue}
                labelColor={colors.text.mediumBlue}
                width={ACTION_BUTTON_WIDTH}
                style={{ border: `2px solid ${colors.text.mediumBlue}` }}
                hoverBackgroundColor={colors.text.mediumBlue}
                labelHoverColor={colors.extra.white}
                iconAfterLabelSvgPath={svgIcons.rightArrow}
                iconAfterLabelSvgColor={colors.text.mediumBlue}
                iconAfterLabelSvgSize={14}
              />
            </HStack>

            {/* Additional Peer Support info */}
            {supportTeamMember.type === 'peer-support' && (
              <HStack width={'100%'} justifyContent={'space-between'} marginTop={'20px'}>
                <VStack gap={0} align={'start'}>
                  <Text variant={'urbanistExtraBoldSmall'} color={'text.mediumGray'}>
                    PEER’S AGE
                  </Text>
                  <Text variant={'urbanistSemiBoldRegular'} color={'text.mediumBlue'}>
                    {supportTeamMember.yearsOfAge}
                  </Text>
                </VStack>
                <VStack gap={0} align={'start'}>
                  <Text variant={'urbanistExtraBoldSmall'} color={'text.mediumGray'}>
                    PHONE NUMBER
                  </Text>
                  <Text variant={'urbanistSemiBoldRegular'} color={'text.mediumBlue'}>
                    {supportTeamMember.phone ?? '/'}
                  </Text>
                </VStack>
              </HStack>
            )}

            {/* Bio */}
            <VStack
              marginTop={supportTeamMember.type === 'member-care' ? '24px' : '10px'}
              align={'start'}
              gap={0}
              position={'relative'}
            >
              <Text variant={'urbanistExtraBoldSmall'} color={'text.mediumGray'}>
                BIO
              </Text>
              <Text
                height={'100%'}
                width={'100%'}
                whiteSpace={'pre-wrap'}
                variant={'urbanistSemiBoldRegular'}
                color={'text.mediumBlue'}
                noOfLines={supportTeamMember.type === 'member-care' ? 14 : 12}
              >
                {supportTeamMember.bio ?? 'No Bio'}
              </Text>
            </VStack>
          </Card>
        </VStack>
      ))}
    </HStack>
  );
};

export default UserSupportTeamSection;
