import {
  Button,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  UseDisclosureReturn,
  VStack,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import colors from '@/theme/colors';
import { capitalize } from '@/utils/stringUtils';
import SvgIcon from '../SvgIcon';
import svgIcons from '@/assets/svg/icons';
import { useMemo } from 'react';

interface Props<TOption> {
  disclosure: UseDisclosureReturn;
  title: string;
  options: readonly TOption[];
  selectedOptions?: TOption[];
  onOptionClick: (option: TOption) => void;
  optionLabelDisplay?: (_: string) => string;
  height?: string;
  width?: number;
  popoverWidth?: number;
  onBottomCloseButtonClick?: () => void;
  bottomSvgIconPath?: string;
  bottomSvgIconSize?: number;
  bottomSvgIconColor?: string;
}

const Dropdown = <TOption,>({
  disclosure,
  title,
  options,
  selectedOptions,
  onOptionClick,
  optionLabelDisplay,
  height = '54px',
  width = 300,
  popoverWidth = width,
  onBottomCloseButtonClick,
  bottomSvgIconPath = svgIcons.circledArrowRight,
  bottomSvgIconSize = 23,
  bottomSvgIconColor = colors.extra.teal,
}: Props<TOption>) => {
  const { isOpen, onOpen, onClose } = disclosure;

  const noValueSelected = !selectedOptions || selectedOptions.length === 0;

  const selectedOptionsLabel = useMemo(() => {
    if (noValueSelected) return '';

    const label = selectedOptions.length
      ? selectedOptions.map((selectedOption, i) => {
          return `${optionLabelDisplay ? optionLabelDisplay(selectedOption as string) : capitalize(selectedOption as string)}${i < selectedOptions.length - 1 ? ', ' : ''}`;
        })
      : '';

    return label;
  }, [noValueSelected, title, selectedOptions]);

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement={'bottom'}>
      <PopoverTrigger>
        <Button
          minHeight={'50px'}
          height={height}
          width={width}
          bg={isOpen ? colors.background.lightBlue : 'transparent'}
          cursor={'pointer'}
          padding={'18px'}
          border={`1px solid ${noValueSelected ? '#E2E8F0' : '#004179'}`}
          boxShadow={`0px 2px ${noValueSelected ? '4px 0px #00417911' : '8px 0px #00417933'}`}
          backgroundColor={isOpen ? 'E2E8F0' : '#FFF'}
          _hover={{ backgroundColor: colors.background.lightBlue }}
        >
          <HStack width={'100%'}>
            <VStack flexGrow={1} alignItems={'flex-start'}>
              {noValueSelected ? (
                <Text
                  fontSize={'14px'}
                  color={noValueSelected ? 'text.mediumGray' : 'text.darkBlue'}
                  lineHeight={'20px'}
                  fontWeight={700}
                  maxWidth={`${width ? width - 50 : 100}px`}
                  isTruncated
                >
                  {title}
                </Text>
              ) : (
                <VStack alignItems={'flex-start'} gap={'2px'} width={'100%'}>
                  <Text variant={'urbanistExtraBoldSmall'} color={'text.mediumGray'}>
                    {title.split(' ')[0].toUpperCase()}
                  </Text>
                  <Text variant={'urbanistSemiBoldRegular'} maxWidth={`${width ? width - 50 : 100}px`} isTruncated>
                    {selectedOptionsLabel}
                  </Text>
                </VStack>
              )}
            </VStack>
            {isOpen ? (
              <ChevronUpIcon boxSize={'5'} color={noValueSelected ? colors.text.mediumGray : colors.text.darkBlue} />
            ) : (
              <ChevronDownIcon boxSize={'5'} color={noValueSelected ? colors.text.mediumGray : colors.text.darkBlue} />
            )}
          </HStack>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        bg="white"
        width={`${popoverWidth}px`}
        border={'1px solid #95B1C9'}
        padding={'10px'}
        borderRadius={'8px'}
        boxShadow={'0px 6px 12px 0px #0E006224'}
      >
        {options.map((option) => {
          const isOptionSelected = selectedOptions?.includes(option);
          return (
            <HStack
              key={option as string}
              cursor={'pointer'}
              justifyContent={'space-between'}
              paddingX={'10px'}
              paddingY={'5px'}
              onClick={() => onOptionClick(option)}
              data-group
            >
              <Text
                _groupHover={{ fontWeight: 700, color: colors.primary[500] }}
                fontWeight={600}
                color={isOptionSelected ? 'primary.500' : 'text.mediumBlue'}
                isTruncated
              >
                {optionLabelDisplay ? optionLabelDisplay(option as string) : capitalize(option as string)}
              </Text>
              {isOptionSelected && <SvgIcon color={colors.primary[500]} iconPath={svgIcons.checkWhite} size={'12'} />}
            </HStack>
          );
        })}
        {(onBottomCloseButtonClick || bottomSvgIconPath) && (
          <VStack padding={'4px'}>
            <Tooltip
              visibility={noValueSelected ? 'visible' : 'hidden'}
              label={'Please select at least one item'}
              padding={'10px'}
              borderRadius={'6px'}
              color={'text.darkBlue'}
              backgroundColor={'extra.white'}
              boxShadow={'0px 2px 8px 0px #00417933'}
              placement="right"
            >
              <VStack
                onClick={() => {
                  if (noValueSelected) {
                  } else {
                    onClose();
                    onBottomCloseButtonClick?.();
                  }
                }}
                borderRadius={'50%'}
                cursor={noValueSelected ? 'not-allowed' : 'pointer'}
                paddingX={'10px'}
              >
                <SvgIcon
                  iconPath={bottomSvgIconPath}
                  color={bottomSvgIconColor}
                  additionalSvgProps={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
                  size={bottomSvgIconSize}
                />
              </VStack>
            </Tooltip>
          </VStack>
        )}
      </PopoverContent>
    </Popover>
  );
};

export default Dropdown;
