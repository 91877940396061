import CustomButton from '@/components/atoms/CustomButton';
import Loader from '@/components/atoms/Loader';
import UserProfileOverview from '@/components/organisms/UserProfileOverview';
import AppPageTemplate from '@/components/templates/AppPageTemplate';
import { mixpanelEvents } from '@/constants/mixpanel';
import useToast from '@/hooks/useToast';
import useTelemetry from '@/providers/TelemetryProvider/useTelemetry';
import { uploadProfilePhoto } from '@/services/api/requests/user';
import { useAppStore } from '@/store/useAppStore';
import { useNavigate } from '@tanstack/react-router';
import React, { useCallback, useEffect, useState } from 'react';

interface Props {
  setOnboardingStep: (step: number) => void;
}

const ProfileOverview = ({ setOnboardingStep }: Props) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { trackEvent } = useTelemetry();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => window.scrollTo({ top: 500, behavior: 'smooth' }), 1500);
  }, []);

  const { user: userData, setUser } = useAppStore();

  const uploadPhoto = async (profilePhoto: File) => {
    try {
      const data = await uploadProfilePhoto(profilePhoto);
      setUser(data);
      toast({
        status: 'success',
        title: 'Success',
        description: 'Profile Photo updated successfully!',
        position: 'top-right',
      });
      return true;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      return false;
    }
  };

  const onClickProceed = useCallback(() => {
    setIsLoading(true);
    trackEvent(mixpanelEvents.USER_SIGN_UP_DONE, userData?.user_metadata);
    navigate({ to: '/onboarding/wholeness-screener' });
  }, []);

  if (!userData) return <Loader />;

  return (
    <AppPageTemplate>
      <UserProfileOverview
        title={'Create Your Profile'}
        updateProfilePhoto={uploadPhoto}
        userData={{ ...userData.user_metadata, email: userData.email }}
        onClickEditContactDetails={() => setOnboardingStep(0)}
        onClickEditDemographics={() => setOnboardingStep(1)}
        initialPreview={userData?.user_metadata.profilePicture}
      />
      <CustomButton
        label="Proceed"
        backgroundColor="secondary.500"
        width={'295px'}
        labelColor="white"
        style={{ marginBottom: '30px' }}
        onClick={onClickProceed}
        isLoading={isLoading}
      />
    </AppPageTemplate>
  );
};

export default ProfileOverview;
