import queryKeys from '@/constants/queryKeys';
import { useAppStore } from '@/store/useAppStore';
import { useInfinitePagination } from '../useInfinitePagination';
import { INFINITE_SCROLL_CARDS_PER_PAGE } from '@/constants';
import { TPeerSupportTeamMemberInfo } from '@/types/PeerSupport.types';
import { getMyOrganizationPeerSupportMembers } from '@/services/api/requests/organization';

const useOrganizationPeerSupportMembers = () => {
  const { user } = useAppStore();

  return useInfinitePagination<TPeerSupportTeamMemberInfo[]>({
    query: getMyOrganizationPeerSupportMembers,
    queryKey: [queryKeys.peerSupport.members, user?.email ?? ''],
    limit: INFINITE_SCROLL_CARDS_PER_PAGE,
  });
};

export default useOrganizationPeerSupportMembers;
