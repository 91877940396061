import logoVariants from '@/assets/img/logo';
import CardHeaderWithImageAndName from '@/components/atoms/CardHeaderWithImageAndName';
import LoadingLogo from '@/components/atoms/LoadingLogo';
import LoadingPage from '@/components/templates/LoadingPage';
import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import useOrganizationPeerSupportMembers from '@/hooks/peersupport/useOrganizationPeerSupportMembers';
import SendEmailModalWithTrigger from '@/modules/user/SendEmailModalWithTrigger';
import colors from '@/theme/colors';
import { TPeerSupportTeamMemberInfo } from '@/types/PeerSupport.types';
import { Card, HStack, Text, VStack } from '@chakra-ui/react';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useCallback } from 'react';

const CARD_WIDTH = '295px';
const CARD_HEIGHT = '400px';

const ACTION_BUTTON_WIDTH = '170px';

const NAME_LENGTH_TO_PUSH_ABOUT_ME = 25;

const PeerSupportDirectory = () => {
  const navigate = useNavigate();

  const { isLoading, data, fetchNextPage, isFetchingNextPage, hasNextPage } = useOrganizationPeerSupportMembers();

  const renderPeerSupportCard = useCallback((peerSupport: TPeerSupportTeamMemberInfo) => {
    return (
      <Card
        key={peerSupport.id}
        size="sm"
        width={CARD_WIDTH}
        height={CARD_HEIGHT}
        variant={'elevated'}
        borderRadius={'8px'}
        padding={'20px'}
        shadow={'0px 0px 20px 0px rgba(0, 65, 121, 0.15)'}
      >
        <CardHeaderWithImageAndName
          onClick={() => navigate({ to: '/peer-support/$peerSupportId', params: { peerSupportId: peerSupport.id } })}
          hasTooltip
          profilePicture={peerSupport.profileImage}
          name={peerSupport.name}
        />

        {/* Additional Peer Support info */}
        <HStack width={'100%'} justifyContent={'space-between'} marginTop={'10px'}>
          <VStack gap={0} align={'start'}>
            <Text variant={'urbanistExtraBoldSmall'} color={'text.mediumGray'}>
              PEER’S AGE
            </Text>
            <Text variant={'urbanistSemiBoldRegular'} color={'text.mediumBlue'}>
              {peerSupport.yearsOfAge}
            </Text>
          </VStack>
          <VStack gap={0} align={'start'}>
            <Text variant={'urbanistExtraBoldSmall'} color={'text.mediumGray'}>
              PHONE NUMBER
            </Text>
            <Text variant={'urbanistSemiBoldRegular'} color={'text.mediumBlue'}>
              {peerSupport.phone ?? '/'}
            </Text>
          </VStack>
        </HStack>

        <VStack height={'100%'} justify={'space-between'}>
          {/* About Me, ex Bio */}
          <VStack marginTop={'20px'} align={'start'} gap={0} width={'100%'}>
            <Text variant={'urbanistExtraBoldSmall'} color={'text.mediumGray'}>
              ABOUT ME
            </Text>
            <Text
              height={'100%'}
              width={'100%'}
              whiteSpace={'pre-wrap'}
              variant={'urbanistSemiBoldRegular'}
              color={'text.mediumBlue'}
              noOfLines={peerSupport.name.length > NAME_LENGTH_TO_PUSH_ABOUT_ME ? 7 : 8}
            >
              {peerSupport.bio ?? 'No About Me'}
            </Text>
          </VStack>

          {/* Action Buttons */}
          <HStack width={'100%'} justifyContent={'center'}>
            <SendEmailModalWithTrigger
              toEmail={peerSupport.email}
              toEmailName={peerSupport.name}
              triggerButtonWidth={ACTION_BUTTON_WIDTH}
              backgroundColor={colors.extra.white}
              noShadow
            />
          </HStack>
        </VStack>
      </Card>
    );
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <VStack width={MAX_CONTAINER_WIDTH} paddingBottom={'40px'}>
      <Text variant={'loraTitle'}>Peer Support Team</Text>
      <HStack gap={'20px'} width={'100%'} wrap={'wrap'} marginTop={'110px'} rowGap={'80px'}>
        {data?.map((peerSupport) => renderPeerSupportCard(peerSupport))}
      </HStack>

      {hasNextPage && !isFetchingNextPage && (
        <HStack cursor={'pointer'} onClick={() => fetchNextPage()} marginY={'30px'}>
          <Text
            variant={'urbanistSemiBoldSmallTitle'}
            cursor={'pointer'}
            _hover={{ fontWeight: 700 }}
            color={'primary.500'}
            fontSize="18px"
          >
            View More
          </Text>
        </HStack>
      )}

      {isFetchingNextPage && (
        <HStack paddingY={'20px'}>
          <LoadingLogo logoVariant={logoVariants.purpleNoText160x160} logoWidth="54px" logoHeight="54px" />
        </HStack>
      )}
    </VStack>
  );
};

export const Route = createFileRoute('/_authenticated/_peer-support/peer-support/directory')({
  component: PeerSupportDirectory,
});
