import {
  PEER_SUPPORT_EVENT_OUTCOME_DISPLAY_LABELS,
  WELLNESS_DOMAINS_DISPLAY_LABELS,
  WELLNESS_SUB_DOMAINS_DISPLAY_LABELS,
} from '@/constants';
import colors from '@/theme/colors';
import { TPeerSupportDashboard, TPeerSupportDashboardEvent } from '@/types/PeerSupport.types';
import { HStack, Text, Tooltip, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { useCallback, useState } from 'react';

interface Props {
  timelineEvents: TPeerSupportDashboard['events'];
}

const COLUMN_1_WIDTH = 160;
const COLUMN_2_WIDTH = 450;
const COLUMN_3_WIDTH = 160;

const COLLAPSED_TIMELINE_EVENTS_COUNT = 3;

const PeerSupportTimeline = ({ timelineEvents }: Props) => {
  const [timelineEventsExpanded, setTimelineEventsExpanded] = useState(false);

  const generateDomainWithSubdomainsDisplay = useCallback(
    (eventDomains: TPeerSupportDashboardEvent['data']['subject']) => {
      return eventDomains.map((eventDomain, i) => (
        <span key={i} style={{ fontWeight: 700, color: colors.text.mediumBlue }}>
          {' '}
          {WELLNESS_DOMAINS_DISPLAY_LABELS[eventDomain.domain]} Wholeness:{' '}
          <span style={{ fontWeight: 500, color: colors.text.mediumBlue }}>
            {eventDomain.subdomains.map((subdomain) => WELLNESS_SUB_DOMAINS_DISPLAY_LABELS[subdomain]).join(', ')}
          </span>
          {i < eventDomains.length - 1 && <span>,</span>}
        </span>
      ));
    },
    [],
  );

  const generateDomainWithSubdomainsTooltip = useCallback(
    (eventDomains: TPeerSupportDashboardEvent['data']['subject']) => {
      return eventDomains.map((eventDomain, i) => (
        <span key={i} style={{ fontWeight: 700, color: colors.text.mediumBlue, whiteSpace: 'pre-wrap' }}>
          {WELLNESS_DOMAINS_DISPLAY_LABELS[eventDomain.domain]} Wholeness:{' '}
          <span style={{ fontWeight: 500, color: colors.text.mediumBlue }}>
            {eventDomain.subdomains.map((subdomain) => WELLNESS_SUB_DOMAINS_DISPLAY_LABELS[subdomain]).join(', ')}
          </span>
          {i < eventDomains.length - 1 && <span>,{'\n'}</span>}
        </span>
      ));
    },
    [],
  );

  return (
    <VStack>
      <Text variant={'loraSmallTitle'} fontWeight={500} marginTop={'60px'} marginBottom={'40px'}>
        Peer Support Timeline
      </Text>
      {/* Table header */}
      <HStack width={'100%'} gap={'30px'}>
        <VStack width={COLUMN_1_WIDTH} align={'start'}>
          <Text variant={'urbanistSmallBold'} fontWeight={800} color={'text.mediumGray'}>
            DATE OF OUTREACH
          </Text>
        </VStack>
        <VStack width={COLUMN_2_WIDTH} align={'start'}>
          <Text variant={'urbanistSmallBold'} fontWeight={800} color={'text.mediumGray'}>
            SUBJECT OF OUTREACH
          </Text>
        </VStack>
        <VStack width={COLUMN_3_WIDTH} align={'start'}>
          <Text variant={'urbanistSmallBold'} fontWeight={800} color={'text.mediumGray'}>
            OUTCOME
          </Text>
        </VStack>
      </HStack>
      {/* Table rows */}
      {timelineEvents.map((event, i) => {
        if (!timelineEventsExpanded && i + 1 > COLLAPSED_TIMELINE_EVENTS_COUNT) {
          return '';
        }

        return (
          <HStack
            key={i}
            width={'100%'}
            gap={'30px'}
            paddingY={'14px'}
            borderBottom={
              i === (timelineEventsExpanded ? timelineEvents.length - 1 : COLLAPSED_TIMELINE_EVENTS_COUNT - 1)
                ? ''
                : '1px solid #BFDAF1'
            }
          >
            <VStack width={COLUMN_1_WIDTH} align={'start'} justify={'start'} height={'100%'}>
              <Text variant={'urbanistSemiBoldRegular'} color={'text.mediumBlue'}>
                {format(event.data.startDate, 'dd. MMM ’yy')}
              </Text>
            </VStack>
            <Tooltip
              label={generateDomainWithSubdomainsTooltip(event.data.subject)}
              padding={'10px'}
              borderRadius={'6px'}
              color={'text.mediumBlue'}
              backgroundColor={'extra.white'}
              boxShadow={'0px 2px 8px 0px #00417933'}
              placement="bottom"
              minWidth={`${COLUMN_2_WIDTH}px`}
            >
              <VStack width={COLUMN_2_WIDTH} pos={'relative'}>
                <Text noOfLines={2} width={'100%'} textAlign={'start'}>
                  {generateDomainWithSubdomainsDisplay(event.data.subject)}
                </Text>
              </VStack>
            </Tooltip>
            <VStack width={COLUMN_3_WIDTH} align={'start'} justify={'start'} height={'100%'}>
              <Text variant={'urbanistSemiBoldRegular'} color={'text.mediumBlue'}>
                {PEER_SUPPORT_EVENT_OUTCOME_DISPLAY_LABELS[event.data.outcome]}
              </Text>
            </VStack>
          </HStack>
        );
      })}

      {timelineEvents.length > COLLAPSED_TIMELINE_EVENTS_COUNT && (
        <Text
          variant={'urbanistExtraBoldSmall'}
          fontSize={'12px'}
          color={'text.mediumGray'}
          cursor={'pointer'}
          _hover={{ fontSize: '13px' }}
          onClick={() => setTimelineEventsExpanded((prev) => !prev)}
          marginY={'20px'}
        >
          VIEW {timelineEventsExpanded ? 'LESS' : 'MORE'}
        </Text>
      )}
    </VStack>
  );
};

export default PeerSupportTimeline;
