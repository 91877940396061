import iconsPng from '@/assets/img/png/icons';
import CustomButton from '@/components/atoms/CustomButton';
import GoBackLeftArrow from '@/components/atoms/GoBackLeftArrow';
import useToast from '@/hooks/useToast';
import { updateUserContactDetails } from '@/services/api/requests/user';
import { useAppStore } from '@/store/useAppStore';
import { TUserContactInfo } from '@/types/User.types';
import { Center, Image, Input, InputGroup, InputRightElement, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { createFileRoute, useRouter } from '@tanstack/react-router';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

const ProfileContactEdit = () => {
  const router = useRouter();
  const toast = useToast();

  const { user, setUser } = useAppStore();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initUserFirstName = user?.user_metadata.firstName ?? user?.firstName ?? '';
  const initUserLastName = user?.user_metadata.lastName ?? user?.lastName ?? '';

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      email: user?.email ?? '',
      firstName: initUserFirstName,
      lastName: initUserLastName,
      phone: user?.user_metadata.phone ?? '',
    },
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().nullable(),
        firstName: yup.string().required('First name is required.'),
        lastName: yup.string().required('Last name is required.'),
        phone: yup.string().required('Phone is required.').min(7, 'Phone number is invalid'),
      }),
    ),
  });

  const { handleSubmit, getValues, register, formState, watch } = methods;

  const emailInput = watch('email');
  const firstNameInput = watch('firstName');
  const lastNameInput = watch('lastName');
  const phoneInput = watch('phone');

  const changesMade = useMemo(() => {
    return (
      emailInput !== user?.email ||
      firstNameInput !== initUserFirstName ||
      lastNameInput !== initUserLastName ||
      phoneInput !== user?.user_metadata.phone
    );
  }, [emailInput, initUserFirstName, firstNameInput, initUserLastName, lastNameInput, phoneInput]);

  const { errors, isValid, touchedFields } = formState;

  const submitDisabled = !isValid || !changesMade;

  const onSubmit = () => {
    setIsSubmitting(true);

    const userContactDetails: Omit<TUserContactInfo, 'email'> & {
      email?: string | null;
    } = getValues();
    delete userContactDetails.email;

    updateUserContactDetails(userContactDetails).then((data) => {
      setUser(data);

      toast({
        status: 'success',
        title: 'Success',
        description: 'User update successful!',
        position: 'top-right',
      });

      setIsSubmitting(false);

      router.history.back();
    });
  };

  return (
    <VStack>
      <GoBackLeftArrow />

      <Center height={'50px'}>
        <Text variant={'lora'} fontSize={'24px'} fontStyle={'oblique'}>
          Edit Your Contact Details
        </Text>
      </Center>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack marginTop={'40px'} gap={2}>
            <InputGroup
              size="lg"
              backgroundColor={'extra.white'}
              height={'50px'}
              alignItems={'center'}
              borderRadius={'8px'}
              border={'1px solid #00417926'}
            >
              <Input
                {...register('email', { required: true })}
                disabled={true} // disabling this always; because we are only coming to this page with an existing email
                _disabled={{ opacity: 1, cursor: 'not-allowed' }}
                maxHeight={'22px'}
                borderWidth={'0'}
                fontSize={'14px'}
                padding={'14px'}
                marginY={'8px'}
                fontWeight={600}
                color={'primary.blueGray'}
              />
              <InputRightElement width="2rem" height={'100%'} cursor={'not-allowed'} marginRight={'10px'}>
                <Image alt="lock" src={iconsPng.lock} width={'18px'} height={'18px'} />
              </InputRightElement>
            </InputGroup>
            <Input
              {...register('firstName', { required: true })}
              className="custom-input"
              placeholder={'Your First Name'}
              fontSize={'16px'}
              _placeholder={{
                fontWeight: '600',
                color: 'background.blueGray',
              }}
              data-state={touchedFields.firstName ? (errors.firstName?.message ? 'invalid' : 'valid') : ''}
            />
            <Input
              {...register('lastName', { required: true })}
              className="custom-input"
              placeholder={'Your Last Name'}
              fontSize={'16px'}
              _placeholder={{
                fontWeight: '600',
                color: 'background.blueGray',
              }}
              data-state={touchedFields.lastName ? (errors.lastName?.message ? 'invalid' : 'valid') : ''}
            />
            <Input
              {...register('phone', { required: true })}
              className="custom-input"
              placeholder={'Your Phone'}
              fontSize={'16px'}
              _placeholder={{
                fontWeight: '600',
                color: 'background.blueGray',
              }}
              data-state={touchedFields.phone ? (errors.phone?.message ? 'invalid' : 'valid') : ''}
            />

            <Text color={'extra.red'} marginBottom="10px" fontWeight={600}>
              {errors.email?.message || errors.phone?.message || errors.firstName?.message || errors.lastName?.message}
            </Text>

            <CustomButton
              backgroundColor="secondary.500"
              label="Update Profile"
              width={'300px'}
              labelColor="white"
              disabled={submitDisabled}
              isLoading={isSubmitting}
              buttonType={submitDisabled ? 'button' : 'submit'}
            />
          </VStack>
        </form>
      </FormProvider>
    </VStack>
  );
};

export const Route = createFileRoute('/_authenticated/_user/_profile/user/profile/contact')({
  component: ProfileContactEdit,
});
