import React from 'react'
import BlueTopPage from '@/components/templates/BlueTopPage'
import onboardingMessages from '@/messages/onboarding'
import { useUserOnboardingStore } from '@/store/useUserOnboardingStore';
import DemographicsForm from '@/components/organisms/DemographicsForm';
import useUpdateDemographics from '@/hooks/user/useUpdateDemographics';
import { TUser, TUserDemographicsInfo } from '@/types/User.types';
import { CENTERED_FORM_WIDTH } from '@/constants/dimensions';


type Props = {
  onSubmitSuccess?: (user: TUser) => void
}

const DemographicsSetup = ({ onSubmitSuccess = () => {} }: Props) => {
  const { demographics: currentUserInfo, setDemographics } = useUserOnboardingStore()

  const { mutate, isPending } = useUpdateDemographics((user) => {
    onSubmitSuccess(user)
  })

  const onFormSubmit = (submittedDemographics: TUserDemographicsInfo) => {
    mutate(submittedDemographics)
    setDemographics(submittedDemographics)
  }

  return (
    <BlueTopPage
      description={onboardingMessages.tellUsMoreDescription}
      title={onboardingMessages.tellUsMore}
      contentMaxWidth={CENTERED_FORM_WIDTH}
      hasOrangeGradient
    >
      <DemographicsForm
        defaultFormValues={currentUserInfo}
        onSubmit={(data) => { onFormSubmit(data)}}
        isLoading={isPending}
      />
    </BlueTopPage>
  )
}

export default DemographicsSetup