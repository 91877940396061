import queryKeys from '@/constants/queryKeys';
import { userDashboardGet } from '@/services/api/requests/user';
import { useQuery } from '@tanstack/react-query';

// Dashboard
const useUserDashboard = () => {
  const { isLoading, data, isError, error, isRefetching, refetch } = useQuery({
    queryKey: [queryKeys.user.dashboard],
    queryFn: async () => {
      const response = await userDashboardGet();

      return response;
    },
  });

  return { isLoading, data, isError, error, isRefetching, refetch };
};

export { useUserDashboard };
