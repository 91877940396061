import Header from '@/components/molecules/Header';
import { CENTERED_FORM_WIDTH, HEADER_HEIGHT } from '@/constants/dimensions';
import colors from '@/theme/colors';
import { Center, Flex, Stack, Text } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

type Props = {
  title: string;
  description: string;
  contentMaxWidth?: string;
  hideButtons?: boolean;
  centeredLogo?: boolean;
  hasOrangeGradient?: boolean;
};

const CENTER_CONTENT_HEIGHT = '340px';

const BlueTopPage = ({
  title,
  description,
  children,
  contentMaxWidth = '820px',
  hideButtons = false,
  centeredLogo = false,
  hasOrangeGradient = false,
}: PropsWithChildren<Props>) => {
  return (
    <Flex width={'100%'} direction={'column'} alignItems={'center'}>
      <Flex
        width={'100%'}
        direction={'column'}
        backgroundColor={'primary.500'}
        alignItems={'center'}
        position={'relative'}
      >
        {hasOrangeGradient && (
          <Flex
            width={'100%'}
            height={'100%'}
            background={`linear-gradient(180deg, ${colors.primary[500]} 0%, #DC733E 100%)`}
            position={'absolute'}
          />
        )}
        <Header variant="transparent" isSticky={false} hideButtons={hideButtons} centeredLogo={centeredLogo} />
        <Center minHeight={CENTER_CONTENT_HEIGHT} marginTop={`${HEADER_HEIGHT / 3}px`} zIndex={2}>
          <Flex maxWidth={{ md: CENTERED_FORM_WIDTH }}>
            <Stack paddingTop={centeredLogo ? '30px' : 0}>
              <Text textAlign={'center'} variant="loraTitle" color="white" fontSize={'26px'}>
                {title}
              </Text>
              <Text
                textAlign={'center'}
                color={'text.veryLightBlue'}
                opacity={0.7}
                lineHeight={'20px'}
                fontWeight={'600'}
                paddingTop={centeredLogo ? '20px' : '10px'}
              >
                {description}
              </Text>
            </Stack>
          </Flex>
        </Center>
      </Flex>
      <Flex width={'100%'} direction={'column'} maxWidth={{ md: contentMaxWidth }} marginTop={'80px'}>
        <Center>{children}</Center>
      </Flex>
    </Flex>
  );
};

export default BlueTopPage;
