import logoVariants from '@/assets/img/logo';
import LoadingLogo from '@/components/atoms/LoadingLogo';
import { TIMELINE_DATE_TEXT_DIMENSION, TIMELINE_ICON_DIMENSION, TIMELINE_ITEMS_GAP } from '@/constants/dimensions';
import colors from '@/theme/colors';
import { TTimeline } from '@/types/Timeline.types';
import getTimelineIconFromType from '@/utils/getTimelineIconFromType';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Center, HStack, Text, VStack } from '@chakra-ui/react';
import { formatDate } from 'date-fns';
import React, { useState } from 'react';

interface Props {
  timelineItems: TTimeline[] | undefined;
  isLoading: boolean;
}

const VERTICAL_LINE_BASE_HEIGHT = 80;

const UserWellnessTimeline = ({ timelineItems, isLoading }: Props) => {
  const [timelineCollapsed, setTimelineCollapsed] = useState(true);

  if (isLoading || !timelineItems) {
    return (
      <VStack width={'1000px'} height={'500px'} position={'relative'}>
        <Center height={'100%'} width={'100%'} position={'absolute'}>
          <LoadingLogo logoVariant={logoVariants.purpleNoText160x160} logoHeight="90px" logoWidth="90px" />
        </Center>
      </VStack>
    );
  }

  return (
    <VStack position={'relative'} justifyContent={'center'} width={'100%'}>
      <Text variant="loraSmallTitle" color="text.mediumGray" fontWeight={'500'} marginBottom={'20px'}>
        My Wellness Timeline
      </Text>
      <VStack
        justifyContent={'flex-start'}
        gap={'40px'}
        padding={'20px'}
        paddingBottom={'20px'}
        maxHeight={timelineCollapsed ? '380px' : ''}
        overflow={'hidden'}
      >
        {timelineItems.map((timelineItem, i) => {
          const isScreenerOrAssessment = timelineItem.type === 'screener' || timelineItem.type === 'assessment';
          const totalScore = timelineItem.data.totalScore * 10;

          const contentDisplay = () => {
            if (isScreenerOrAssessment) {
              return (
                <Text variant={'urbanistSemiBold'} color="text.mediumBlue">
                  Took the screener
                </Text>
              );
            }

            if (
              timelineItem.type === 'memberCareNote' ||
              timelineItem.type === 'selfNote' ||
              timelineItem.type === 'observation'
            ) {
              return (
                <VStack gap={0}>
                  <HStack gap={'4px'} width={'100%'} justifyContent={'flex-start'}>
                    {timelineItem.type === 'memberCareNote' ? (
                      <>
                        <Text variant={'urbanistSemiBold'} color="text.mediumBlue">
                          {timelineItem.type === 'memberCareNote' ? 'Note' : 'Observation'} from
                        </Text>
                        <Text variant={'urbanistSemiBold'} color="primary.500">
                          {timelineItem.data.memberCare?.firstName ?? 'Member Specialist'}
                          {timelineItem.data.memberCare?.firstName ? ' ' : ''}
                          {timelineItem.data.memberCare?.lastName}:
                        </Text>
                      </>
                    ) : (
                      <Text variant={'urbanistSemiBold'} color="text.mediumBlue">
                        Note to yourself:
                      </Text>
                    )}
                  </HStack>
                  <Text
                    variant={'loraItalic'}
                    color={'text.mediumBlue'}
                    maxWidth={'650px'}
                    textAlign={'start'}
                    width={'100%'}
                  >
                    {timelineItem.data.content}
                  </Text>
                </VStack>
              );
            }

            return timelineItem.data.content;
          };

          return (
            <HStack position={'relative'} width={'100%'} gap={`${TIMELINE_ITEMS_GAP}px`} key={timelineItem.id}>
              <Text variant={'urbanistSemiBold'} color={'text.mediumBlue'} width={`${TIMELINE_DATE_TEXT_DIMENSION}px`}>
                {formatDate(new Date(timelineItem.createdAt), 'dd. MMM')}
              </Text>
              <Center
                width={TIMELINE_ICON_DIMENSION}
                height={TIMELINE_ICON_DIMENSION}
                backgroundColor={'extra.white'}
                borderRadius={'full'}
                boxShadow={'0px 3.18px 15.88px 0px #00417933'}
                zIndex={2}
              >
                {getTimelineIconFromType(timelineItem.type)}
              </Center>
              <HStack gap={'4px'}>
                {contentDisplay()}
                {isScreenerOrAssessment && (
                  <Text variant={'urbanistSemiBold'} color={'primary.500'}>
                    Wellness Score: {totalScore.toFixed()}.
                  </Text>
                )}
              </HStack>
              {/* Vertical Gray Line */}
              {i < timelineItems.length - 1 && (
                <Box
                  position={'absolute'}
                  top={TIMELINE_ICON_DIMENSION}
                  left={`${TIMELINE_DATE_TEXT_DIMENSION + TIMELINE_ITEMS_GAP + TIMELINE_ICON_DIMENSION / 2 - 1}px`}
                  // Gray Line Height is: VERTICAL_LINE_BASE_HEIGHT + content_character_count / 10    *    12px for 1st and 2nd-to-last   or   6px for all the other elements
                  height={`${VERTICAL_LINE_BASE_HEIGHT + ((timelineItem.data.content?.length ?? 1) / 10) * (i === timelineItems.length - 2 || i === 0 ? 12 : 6)}px`}
                  width={'2px'}
                  backgroundColor={'#CBDEED'}
                  zIndex={1}
                ></Box>
              )}
            </HStack>
          );
        })}
      </VStack>
      {timelineItems.length > 4 && (
        <Center
          id="the-radial-color-overlay"
          position="absolute"
          transition={'height 0.1s'}
          bgGradient={
            timelineCollapsed
              ? `linear-gradient(to top, ${colors.background.lightBlue}, ${colors.background.lightBlue}, ${colors.background.lightBlue}10)`
              : `radial-gradient(${colors.background.lightBlue}, ${colors.background.lightBlue}10)`
          }
          width={'100%'}
          height={timelineCollapsed ? '150px' : '100px'}
          bottom={timelineCollapsed ? '-50px' : '-85px'}
          left={0}
          zIndex={10}
        >
          <HStack>
            {timelineCollapsed ? (
              <ChevronDownIcon boxSize={'6'} color={colors.text.mediumGray} />
            ) : (
              <ChevronUpIcon boxSize={'6'} color={colors.text.mediumGray} />
            )}
            <Text
              variant={'urbanistSemiBoldSmallTitle'}
              zIndex={11}
              cursor={'pointer'}
              _hover={{ fontWeight: 700 }}
              color={'primary.500'}
              fontSize="18px"
              onClick={() => setTimelineCollapsed((prev) => !prev)}
            >
              Read {timelineCollapsed ? 'more' : 'less'}
            </Text>
          </HStack>
        </Center>
      )}
    </VStack>
  );
};

export default UserWellnessTimeline;
