import svgFiles from '@/assets/svg/svgFiles';
import CustomButton from '@/components/atoms/CustomButton';
import LoadingPage from '@/components/templates/LoadingPage';
import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import useMemberCareFromMyOrgById from '@/hooks/membercare/useMemberCareFromMyOrgById';
import { getAgeFromBirthDay } from '@/utils/stringUtils';
import { HStack, Image, Text, VStack } from '@chakra-ui/react';
import { createFileRoute } from '@tanstack/react-router';
import { useRef } from 'react';
import SvgIconCalendar from '@/components/atoms/SvgIconCalendar';
import SendEmailModalWithTrigger from '@/modules/user/SendEmailModalWithTrigger';
import colors from '@/theme/colors';

const MemberCareProfile = () => {
  const { memberCareId } = Route.useParams();

  const { data: memberCare, isLoading } = useMemberCareFromMyOrgById(memberCareId);

  const calendlyRef = useRef<HTMLAnchorElement>(null);

  if (isLoading || !memberCare) return <LoadingPage />;

  return (
    <VStack width={MAX_CONTAINER_WIDTH}>
      {/* Actual page content */}
      <VStack width={'100%'} marginBottom={'50px'} alignItems={'center'} gap={'4px'}>
        <VStack
          borderWidth={'4px'}
          borderRadius={'full'}
          borderColor={'white'}
          shadow={'0px 6px 24px 0px rgba(14, 0, 98, 0.2)'}
          overflow={'hidden'}
        >
          <Image
            src={
              memberCare.user_metadata.profilePicture ?? memberCare.user_metadata.profile_photo ?? svgFiles.genericUser
            }
            alt={memberCare.firstName ?? memberCare.user_metadata.firstName}
            width={120}
            height={120}
            borderRadius={'full'}
            objectFit={'cover'}
            style={{ backgroundColor: 'white' }}
          />
        </VStack>
        <Text variant={'loraSmallTitle'} marginTop={'10px'}>
          {memberCare.firstName ?? memberCare.user_metadata.firstName}{' '}
          {memberCare.lastName ?? memberCare.user_metadata.lastName}
        </Text>
        <Text variant={'urbanistSemiBold'}>Member Care Specialist</Text>
        {memberCare.user_metadata.dateOfBirth && (
          <Text
            variant={'urbanistRegular'}
            color={'text.mediumGray'}
          >{`${getAgeFromBirthDay(memberCare.user_metadata.dateOfBirth)} years old ${memberCare.sex.toLowerCase()}`}</Text>
        )}

        {/* Action buttons */}
        <HStack gap={8} marginTop={'40px'}>
          <SendEmailModalWithTrigger
            toEmail={memberCare.email}
            toEmailName={memberCare.user_metadata.firstName}
            triggerButtonWidth="170px"
            backgroundColor={colors.extra.white}
            labelColor={colors.text.mediumBlue}
            triggerButtonTransparent={false}
            hoveredBackgroundColor={colors.extra.white}
            hoveredLabelColor={colors.extra.black}
          />
          <a
            ref={calendlyRef}
            target="_blank"
            href={memberCare.user_metadata?.calendly_link}
            style={{ display: 'none' }}
          >
            {memberCare.user_metadata?.calendly_link}
          </a>

          <CustomButton
            label="Schedule call"
            backgroundColor="primary.500"
            labelColor="extra.white"
            onClick={() => {
              calendlyRef.current?.click();
            }}
            width={'170px'}
            style={{ border: `2px solid ${colors.primary[500]}` }}
            labelHoverColor="white"
            iconBeforeLabelJSX={<SvgIconCalendar color={colors.extra.white} size={18} />}
            iconBeforeLabelHoveredJSX={<SvgIconCalendar color={colors.extra.white} size={18} />}
          />
        </HStack>

        {/* Bio */}
        <VStack
          backgroundColor={'white'}
          padding={'40px'}
          marginTop={'40px'}
          borderRadius={'12px'}
          alignItems={'flex-start'}
          minWidth={'500px'}
          maxWidth={'690px'}
          boxShadow={'0px 0px 20px 0px #00417926'}
        >
          <Text variant={'loraSmallTitle'} textAlign={'start'}>
            About Me
          </Text>
          <Text
            variant={'urbanistMediumLarge'}
            lineHeight={'22px'}
            color={'text.mediumBlue'}
            marginTop={'10px'}
            whiteSpace={'pre-wrap'}
          >
            {memberCare.user_metadata.bio}
          </Text>
        </VStack>
      </VStack>
    </VStack>
  );
};

export const Route = createFileRoute('/_authenticated/_member-care/member-care/$memberCareId')({
  component: MemberCareProfile,
});
