import queryKeys from '@/constants/queryKeys';
import { getMyOrganizationMemberCares } from '@/services/api/requests/member-care';
import { useAppStore } from '@/store/useAppStore';
import { useInfinitePagination } from '../useInfinitePagination';
import { TMemberCare } from '@/types/MemberCare.types';
import { INFINITE_SCROLL_CARDS_PER_PAGE } from '@/constants';

const useOrganizationMemberCares = () => {
  const { user } = useAppStore();

  return useInfinitePagination<TMemberCare[]>({
    query: getMyOrganizationMemberCares,
    queryKey: [queryKeys.memberCare.myOrganizationMemberCares, user?.email ?? ''],
    limit: INFINITE_SCROLL_CARDS_PER_PAGE,
  });
};

export default useOrganizationMemberCares;
