import { TOrganization, TOrganizationDashboard } from '@/types/Organization.types';
import { api, TCustomAxiosResponse } from '..';
import { TRepresentativeFormData } from '@/modules/onboarding/organization/types';
import { TUser } from '@/types/User.types';
import { TMemberCare } from '@/types/MemberCare.types';
import { INFINITE_SCROLL_CARDS_PER_PAGE } from '@/constants';
import { TPeerSupportTeamMemberInfo } from '@/types/PeerSupport.types';

const root = 'v1/organization';

const endpoints = {
  organization: `${root}`,
  myOrganization: `${root}/my`,
  myOrganizationDashboard: `${root}/my/dashboard`,
  myOrganizationPeerSupportMembers: `${root}/my/peer-support`,
  inviteUsers: `${root}/invite/users`,
  members: `${root}/my/users`,
  deleteOrgMember: `${root}/employee`,
  logoUpload: `${root}/my/upload-logo`,
  membercareFromMyOrgById: `${root}/membercare`,
};

export const myOrganizationGet = async (): Promise<TOrganization> => {
  const { data } = await api.get<TCustomAxiosResponse<TOrganization>>(endpoints.myOrganization);
  const organization = data.data;

  return organization;
};

export const myOrganizationDashboardGet = async (): Promise<TOrganizationDashboard> => {
  const { data } = await api.get<TCustomAxiosResponse<TOrganizationDashboard>>(endpoints.myOrganizationDashboard);
  const organizationDash = data.data;

  return organizationDash;
};

export const updateOrganizationByIdPut = async (organizationId: string, orgInfo: TRepresentativeFormData) => {
  const { data } = await api.put<TCustomAxiosResponse<TOrganization>>(`${endpoints.organization}/${organizationId}`, {
    ...orgInfo,
  });
  const updatedOrganization = data.data;
  return updatedOrganization;
};

export const inviteUsersToOrganizationPost = async (
  users: { email: string; phone: string; firstName: string; lastName: string }[],
) => {
  const { data } = await api.post<TCustomAxiosResponse<unknown>>(endpoints.inviteUsers, {
    users,
  });

  return data.data;
};

export const reinviteUsersToOrganizationPost = async (organizationId: string, userId: string) => {
  const { data } = await api.post<TCustomAxiosResponse<unknown>>(
    `${endpoints.organization}/${organizationId}/reinvite/${userId}`,
  );

  return data.data;
};

export const uploadOrganizationLogoPost = async (file: File): Promise<TOrganization> => {
  const formData = new FormData();
  formData.append('file', new File([file], file.name));

  const { data } = await api.post<TCustomAxiosResponse<TOrganization>>(endpoints.logoUpload, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  const organization = data.data;
  return organization;
};

export const organizationMembersForAdminGet = async (
  page: number,
  _: string,
  searchFilterText?: string,
): Promise<TCustomAxiosResponse<TUser[]>> => {
  const { data } = await api.get(endpoints.members, { params: { page, search: searchFilterText } });

  return data;
};

export const removeMemberFromOrganization = async (
  orgMemberId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<TCustomAxiosResponse<any>> => {
  const { data } = await api.delete(`${endpoints.deleteOrgMember}/${orgMemberId}`);
  return data;
};

export const getMemberCareFromMyOrgById = async (memberCareId: string): Promise<TMemberCare> => {
  const { data } = await api.get(`${endpoints.membercareFromMyOrgById}/${memberCareId}`);

  const memberCare = data.data as TMemberCare;

  return memberCare;
};

export const getPeerSupportFromMyOrgById = async (peerSupportId: string): Promise<TPeerSupportTeamMemberInfo> => {
  const { data } = await api.get(`${endpoints.myOrganizationPeerSupportMembers}/${peerSupportId}`);

  const peerSupport = data.data as TPeerSupportTeamMemberInfo;

  return peerSupport;
};

export const getMyOrganizationPeerSupportMembers = async (
  page: number,
  limit = INFINITE_SCROLL_CARDS_PER_PAGE,
): Promise<TCustomAxiosResponse<TPeerSupportTeamMemberInfo[]>> => {
  const { data } = await api.get(endpoints.myOrganizationPeerSupportMembers, { params: { page, limit } });

  return data;
};
