import { TMemberCare } from '@/types/MemberCare.types';
import { api, TCustomAxiosResponse } from '..';
import { INFINITE_SCROLL_CARDS_PER_PAGE } from '@/constants';

const root = 'v1/membercare';

const endpoints = {
  my: `${root}/organization/my/member-cares`,
};

export const getMyOrganizationMemberCares = async (
  page: number,
  limit = INFINITE_SCROLL_CARDS_PER_PAGE,
): Promise<TCustomAxiosResponse<TMemberCare[]>> => {
  const { data } = await api.get(endpoints.my, { params: { page, limit } });

  return data;
};
