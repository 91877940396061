import { TLegalDocument, TLegalDocumentAcceptanceStatus, TLegalDocumentType } from '@/types/Legal.types';
import { api, TCustomAxiosResponse } from '..';

const root = 'v1/legal-documents';

const endpoints = {
  activeDocument: `${root}/active`,
  acceptedDocuments: `${root}/acceptance-status`,
  acceptDocument: `${root}/accept`,
};

export const privacyPolicyGet = async (): Promise<TLegalDocument> => {
  const type: TLegalDocumentType = 'PRIVACY_POLICY';
  const { data } = await api.get<TCustomAxiosResponse<TLegalDocument>>(`${endpoints.activeDocument}/${type}`);

  const document = data.data;
  return document;
};

export const termsAndConditionsGet = async () => {
  const type: TLegalDocumentType = 'TERMS_AND_CONDITIONS';
  const { data } = await api.get<TCustomAxiosResponse<TLegalDocument>>(`${endpoints.activeDocument}/${type}`);

  const document = data.data;
  return document;
};

export const acceptedDocumentsGet = async (): Promise<TLegalDocumentAcceptanceStatus[]> => {
  const { data } = await api.get<
    TCustomAxiosResponse<{ userId: string; status: Record<string, TLegalDocumentAcceptanceStatus> }>
  >(endpoints.acceptedDocuments);
  const statuses = data.data.status;
  const documents = Object.values(statuses);

  return documents;
};

export const acceptEulaDocumentPost = async (documentId: string): Promise<{ success: boolean }> => {
  const { data } = await api.post<TCustomAxiosResponse<{ success: boolean }>>(
    `${endpoints.acceptDocument}/${documentId}`,
  );
  return data.data;
};
