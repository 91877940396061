import ModalContainer from '@/components/molecules/ModalContainer';
import { TUserContactInfo } from '@/types/User.types';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EMAIL_REGEX } from '@/constants/stringVars';
import * as yup from 'yup';
import useToast from '@/hooks/useToast';
import useTelemetry from '@/providers/TelemetryProvider/useTelemetry';
import { Center, Image, Input, Text, VStack } from '@chakra-ui/react';
import { FORM_FIELD_WIDTH } from '@/constants/dimensions';
import iconsPng from '@/assets/img/png/icons';
import CustomButton from '@/components/atoms/CustomButton';
import useAddMemberToOrganization from '@/hooks/organization/addMemberToOrganization';

interface Props {
  addMemberModalVisible: boolean;
  setAddMemberModalVisible: Dispatch<SetStateAction<boolean>>;
  reFetch: () => void;
}

const addMemberSchema = yup
  .object()
  .shape({
    firstName: yup
      .string()
      .matches(/^[a-zA-Z ]+$/, 'Only alphabetic characters allowed')
      .required('First Name is required.'),
    lastName: yup
      .string()
      .matches(/^[a-zA-Z ]+$/, 'Only alphabetic characters allowed')
      .required('Last Name is required.'),
    phone: yup.string().required('Phone is required.'),
    email: yup.string().matches(EMAIL_REGEX, 'Wrong email format').required('Email is required.'),
  })
  .required();

const OrganizationRosterAddMemberModal = ({ addMemberModalVisible, setAddMemberModalVisible, reFetch }: Props) => {
  const toast = useToast();
  const { trackEvent } = useTelemetry();

  const {
    register,
    getValues,
    reset: resetUserForm,
    formState,
  } = useForm<TUserContactInfo>({
    resolver: yupResolver(addMemberSchema),
    mode: 'all',
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
    },
  });
  const { isValid, errors, touchedFields } = formState;

  const { isPending, mutate: inviteMembersToOrg } = useAddMemberToOrganization(() => {
    reFetch();
    resetUserForm();
    setAddMemberModalVisible(false);
  });

  const addMember = useCallback(async () => {
    const usersToInvite: TUserContactInfo[] = [getValues()];
    inviteMembersToOrg(usersToInvite);
  }, [getValues, resetUserForm, toast, trackEvent, reFetch]);

  return (
    <ModalContainer
      isOpen={addMemberModalVisible}
      onClose={() => setAddMemberModalVisible(false)}
      isBlur={false}
      width={'40%'}
      height={'20vh'}
      backgroundColor={'background.lightBlue'}
    >
      <Text variant={'loraTitle'} textAlign={'center'} fontSize={'26px'} marginTop={'30px'}>
        Add Member to your Organization
      </Text>
      <Center marginTop={'20px'}>
        <Image alt="add-member" src={iconsPng.addMember} width={120} height={120} />
      </Center>
      <Center>
        <VStack marginY={'40px'} width={FORM_FIELD_WIDTH} gap={5}>
          <Input
            {...register('firstName', {
              required: true,
            })}
            className="custom-input"
            placeholder={'First Name'}
            _placeholder={{
              fontWeight: '600',
              color: 'background.blueGray',
            }}
            data-state={touchedFields.firstName ? (errors.firstName?.message ? 'invalid' : 'valid') : ''}
          />
          {errors.firstName && <Text variant={'error'}>{errors.firstName.message}</Text>}
          <Input
            {...register('lastName', {
              required: true,
            })}
            className="custom-input"
            placeholder={'Last Name'}
            _placeholder={{
              fontWeight: '600',
              color: 'background.blueGray',
            }}
            data-state={touchedFields.lastName ? (errors.lastName?.message ? 'invalid' : 'valid') : ''}
          />
          {errors.lastName && <Text variant={'error'}>{errors.lastName.message}</Text>}
          <Input
            {...register('phone', {
              required: true,
            })}
            className="custom-input"
            placeholder={'Phone Number'}
            _placeholder={{
              fontWeight: '600',
              color: 'background.blueGray',
            }}
            data-state={touchedFields.phone ? (errors.phone?.message ? 'invalid' : 'valid') : ''}
          />
          {errors.phone && <Text variant={'error'}>{errors.phone.message}</Text>}

          <Input
            {...register('email', { required: true })}
            className="custom-input"
            placeholder={'Email Address'}
            type="email"
            _placeholder={{
              fontWeight: '600',
              color: 'background.blueGray',
            }}
            data-state={touchedFields.email ? (errors.email?.message ? 'invalid' : 'valid') : ''}
          />
          {errors.email && <Text variant={'error'}>{errors.email.message}</Text>}
          <CustomButton
            style={{ marginTop: '20px' }}
            backgroundColor="secondary.500"
            label="Invite Member"
            width={FORM_FIELD_WIDTH}
            labelColor="white"
            onClick={addMember}
            disabled={!isValid}
            isLoading={isPending}
          />
        </VStack>
      </Center>
    </ModalContainer>
  );
};

export default OrganizationRosterAddMemberModal;
