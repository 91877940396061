import svgIcons from '@/assets/svg/icons';
import svgFiles from '@/assets/svg/svgFiles';
import CustomButton from '@/components/atoms/CustomButton';
import SvgIcon from '@/components/atoms/SvgIcon';
import LoadingPage from '@/components/templates/LoadingPage';
import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import usePeerSupportById from '@/hooks/peersupport/usePeerSupportFromMyOrgById';
import SendEmailModalWithTrigger from '@/modules/user/SendEmailModalWithTrigger';
import colors from '@/theme/colors';
import { HStack, Image, Text, VStack } from '@chakra-ui/react';
import { createFileRoute } from '@tanstack/react-router';
import { useRef } from 'react';

const PeerSupportProfile = () => {
  const { peerSupportId } = Route.useParams();

  const { data: peerSupport, isLoading } = usePeerSupportById(peerSupportId);

  const phoneLinkRef = useRef<HTMLAnchorElement>(null);

  if (isLoading || !peerSupport) return <LoadingPage />;

  return (
    <VStack width={MAX_CONTAINER_WIDTH}>
      {/* Actual page content */}
      <VStack width={'100%'} marginBottom={'50px'} alignItems={'center'} gap={'4px'}>
        <VStack
          borderWidth={'4px'}
          borderRadius={'full'}
          borderColor={'white'}
          shadow={'0px 6px 24px 0px rgba(14, 0, 98, 0.2)'}
          overflow={'hidden'}
        >
          <Image
            src={peerSupport.profileImage ?? svgFiles.genericUser}
            alt={peerSupport.name}
            width={120}
            height={120}
            borderRadius={'full'}
            objectFit={'cover'}
            style={{ backgroundColor: 'white' }}
          />
        </VStack>
        <Text variant={'loraSmallTitle'} marginTop={'10px'}>
          {peerSupport.name}
        </Text>
        <Text variant={'urbanistSemiBold'}>Peer Support Specialist</Text>
        {peerSupport.yearsOfAge && (
          <Text variant={'urbanistRegular'} color={'text.mediumGray'}>{`${peerSupport.yearsOfAge} years old`}</Text>
        )}

        {/* Action buttons */}
        <HStack gap={8} marginTop={'40px'}>
          <SendEmailModalWithTrigger
            toEmail={peerSupport.email}
            toEmailName={peerSupport.name}
            triggerButtonWidth="170px"
            backgroundColor={colors.extra.white}
            labelColor={colors.text.mediumBlue}
            triggerButtonTransparent={false}
            hoveredBackgroundColor={colors.extra.white}
            hoveredLabelColor={colors.extra.black}
          />
          <a
            ref={phoneLinkRef}
            href={`tel:+${peerSupport.phone?.includes('+') ? peerSupport.phone.substring(1) : peerSupport.phone}`}
            style={{ display: 'none' }}
          >
            {peerSupport.phone}
          </a>

          <CustomButton
            label="Phone call"
            backgroundColor="primary.500"
            labelColor="extra.white"
            onClick={() => phoneLinkRef.current?.click()}
            width={'170px'}
            style={{ border: `2px solid ${colors.primary[500]}` }}
            labelHoverColor="white"
            iconBeforeLabelJSX={<SvgIcon iconPath={svgIcons.phone} color={colors.extra.white} size={18} />}
          />
        </HStack>

        {/* Bio */}
        <VStack
          backgroundColor={'white'}
          padding={'40px'}
          marginTop={'40px'}
          borderRadius={'12px'}
          alignItems={'flex-start'}
          minWidth={'500px'}
          maxWidth={'690px'}
          boxShadow={'0px 0px 20px 0px #00417926'}
        >
          <Text variant={'loraSmallTitle'} textAlign={'start'}>
            About Me
          </Text>
          <Text
            variant={'urbanistMediumLarge'}
            lineHeight={'22px'}
            color={'text.mediumBlue'}
            marginTop={'10px'}
            whiteSpace={'pre-wrap'}
          >
            {peerSupport.bio ?? 'No Bio'}
          </Text>
        </VStack>
      </VStack>
    </VStack>
  );
};

export const Route = createFileRoute('/_authenticated/_peer-support/peer-support/$peerSupportId')({
  component: PeerSupportProfile,
});
