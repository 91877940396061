import svgFiles from '@/assets/svg/svgFiles';
import colors from '@/theme/colors';
import { Image, Input, InputGroup, InputLeftElement, InputRightElement, Text, VStack } from '@chakra-ui/react';
import React, { useRef } from 'react';
import { UseFormRegister } from 'react-hook-form';

interface Props {
  title?: string;
  value?: string;
  minValue?: string;
  errorMessage?: string;
  registerFormField: UseFormRegister<any>;
  registerFormFieldName: string;
}

const DateInput = ({ title, value, minValue, registerFormField, registerFormFieldName, errorMessage }: Props) => {
  const dateInputRef = useRef<HTMLInputElement | null>(null);
  const { ref: refCallback, ...registerFormResult } = registerFormField(registerFormFieldName);

  return (
    <VStack width={'300px'} justifyContent={'center'}>
      <InputGroup
        cursor={'pointer'}
        borderColor={!!value ? colors.text.mediumBlue : undefined}
        _hover={{ borderColor: !!value ? colors.text.mediumBlue : undefined }}
        onClick={() => {
          try {
            dateInputRef.current?.showPicker();
          } catch (e) {}
        }}
      >
        <InputLeftElement
          width={'155px'} // to cover the dd.mm.yyyy
          height={'90%'}
          backgroundColor={'white'}
          justifyContent={'flex-start'}
          top={'2px'}
          left={'2px'}
          borderRadius={'5px'}
          display={value ? 'none' : 'flex'}
          onClick={() => {
            try {
              dateInputRef.current?.showPicker();
            } catch (e) {}
          }}
        >
          <Text paddingLeft={'18px'} variant={'urbanistSemiBoldRegular'} color={'text.mediumGray'}>
            {title}
          </Text>
        </InputLeftElement>
        <Input
          type="date"
          cursor={'pointer'}
          paddingLeft={value ? '18px' : '0'}
          {...registerFormResult}
          ref={(e) => {
            refCallback(e);
            dateInputRef.current = e;
          }}
          min={minValue}
          className="custom-input"
        />
        <InputRightElement
          position={'absolute'}
          top={'6px'}
          right={'6px'}
          backgroundColor={'white'}
          width={'70px'}
          paddingLeft={'20px'}
        >
          <Image src={svgFiles.calendarGray} />
        </InputRightElement>
      </InputGroup>

      {errorMessage && <Text variant={'error'}>{errorMessage}</Text>}
    </VStack>
  );
};

export default DateInput;
