import { Dispatch, useEffect, useMemo, useState } from 'react';

import * as d3 from 'd3';

import colorConsts from '@/theme/colors';
import { Center, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { default as themeColors } from '@/theme/colors';

export type WellnessItemType = 'work' | 'mental' | 'physical';

type THoverTooltipSide = 'left' | 'right';

export type TChartDataItem = {
  name: string;
  value: number;
  itemType?: WellnessItemType;
  description?: string;
};

export type TChartColorItem = {
  name: string;
  value: string;
};

export type THoverElement = {
  x: number;
  y: number;
  text: string;
  value: number;
  color: string;
  description?: string;
} | null;

const CHART_DEFAULT_SVG_WIDTH = 220;
const CHART_PADDING = 25;
const CHART_SHADOW_PADDING = 15;
const MAX_CHART_WIDTH = 500;
const CHART_PAD_ANGLE = 0.05;

const renderChart = (
  width = CHART_DEFAULT_SVG_WIDTH,
  data: TChartDataItem[],
  colors: TChartColorItem[],
  hoverElementInfo: THoverElement,
  setHoverElementInfo: Dispatch<THoverElement>,
  chartPadding: number,
  chartShadowPadding: number,
  chartContainerId: string,
  onChartSectionClick?: (chartSection: string) => void,
  hoverTooltipSide?: THoverTooltipSide,
) => {
  const height = Math.min(width, MAX_CHART_WIDTH);
  const radius = Math.min(width, height) / 2;

  const arc = d3
    .arc<d3.PieArcDatum<TChartDataItem>>()
    .innerRadius(radius / 2)
    .outerRadius(radius - 1)
    .cornerRadius(10);

  const padAngle = data.find((element) => element.value === 100) ? 0 : CHART_PAD_ANGLE;

  const pie = d3
    .pie<TChartDataItem>()
    .sort(null)
    .value((d) => d.value)
    .padAngle(padAngle);

  const color = colors
    ? (itemName: string) => {
        const foundColor = colors.find((color: TChartColorItem) => color.name === itemName)?.value;
        return hoverElementInfo && hoverElementInfo.color !== foundColor ? `${foundColor}80` : foundColor;
      }
    : d3
        .scaleOrdinal()
        .domain(data.map((d) => d.name))
        .range(d3.quantize((t) => d3.interpolateSpectral(t * 0.8 + 0.1), data.length).reverse());

  const theChartElement = d3.select(`#${chartContainerId}`);
  theChartElement.selectChildren().remove(); // to make sure we always have just one chart rendered

  const chartSvg = theChartElement
    .append('svg')
    .attr('width', width + chartShadowPadding)
    .attr('height', height + chartShadowPadding)
    .attr('viewBox', [
      -(width + chartShadowPadding) / 2,
      -(height + chartShadowPadding) / 2,
      width + chartShadowPadding,
      height + chartShadowPadding,
    ]);

  let timeoutRef: ReturnType<typeof setTimeout>;

  const shadowHeight = width / CHART_DEFAULT_SVG_WIDTH > 1 ? 4 : 2;

  chartSvg
    .append('g')
    .selectAll()
    .data(pie(data))
    .join('path')
    .attr('fill', (d) => color(d.data.name) as string)
    .attr('d', arc)
    .attr('filter', (d) =>
      d.data.name === hoverElementInfo?.text
        ? `drop-shadow(0px ${shadowHeight}px ${shadowHeight}px rgba(0, 0, 0, 0.25)`
        : '',
    )
    .attr('cursor', !!onChartSectionClick ? 'pointer' : 'default')
    .on('mousemove', (e, d) => {
      clearTimeout(timeoutRef);

      let calculatedX = 0;
      if (hoverTooltipSide === 'left') {
        calculatedX = -width + chartPadding + e.offsetX / 2;
      }
      if (hoverTooltipSide === 'right') {
        calculatedX = width - chartPadding + e.offsetX / 2;
      }
      if (!hoverTooltipSide) {
        calculatedX =
          e.offsetX < width / 2 ? -width + chartPadding + e.offsetX / 2 : width - chartPadding + e.offsetX / 2;
      }

      setHoverElementInfo({
        text: d.data.name,
        value: d.data.value,
        x: calculatedX,
        y: e.offsetY,
        color: colors.find((color: TChartColorItem) => color.name === d.data.name)?.value ?? 'black',
        description: d.data.description,
      });
    })
    .on('mouseleave', () => {
      timeoutRef = setTimeout(() => {
        setHoverElementInfo(null);
      }, 300);
    })
    .on('click', (_, d) => {
      if (onChartSectionClick) {
        onChartSectionClick(d.data.name);
        setHoverElementInfo(null);
      }
    })
    // .append('title') // this adds the small text hover tooltip after 2 seconds
    .text((d) => `${d.data.name}: ${d.data.value.toLocaleString()}%`);

  return chartSvg.node();
};

const DoughnutChart = ({
  chartSvgWidth = CHART_DEFAULT_SVG_WIDTH,
  data,
  colors,
  onChartSectionClick,
  chartPadding = CHART_PADDING,
  chartShadowPadding = CHART_SHADOW_PADDING,
  chartBackgroundColor = 'white',
  chartAndLegendGap = '40px',
  legendItemsVerticalGap = '20px',
  legendDotAndTextSize = '16px',
  legendLabelIncludesValue = false,
  hoverCorrection = 0,
  hoverTooltipSide,
  tooltipElementConfig = { hasDescription: true, hasText: true, hasValue: true, width: '200px' },
  hasShadow = true,
  hasLegend = true,
  hasTooltip = true,
  titleElement = null,
  centeredElement = null,
  footerElement = null,
  additionalSvgId = '',
}: {
  chartSvgWidth?: number;
  data: TChartDataItem[];
  colors?: TChartColorItem[];
  onChartSectionClick?: (chartSection: string) => void;
  chartPadding?: number;
  chartShadowPadding?: number;
  chartBackgroundColor?: string;
  chartAndLegendGap?: string;
  legendItemsVerticalGap?: string;
  legendDotAndTextSize?: string;
  legendLabelIncludesValue?: boolean;
  hoverCorrection?: number;
  hoverTooltipSide?: THoverTooltipSide;
  tooltipElementConfig?: { width?: string; hasValue?: boolean; hasText?: boolean; hasDescription?: boolean }; // send empty object to make tooltip not display
  hasShadow?: boolean;
  hasLegend?: boolean;
  hasTooltip?: boolean;
  titleElement?: JSX.Element | null;
  centeredElement?: JSX.Element | null;
  footerElement?: JSX.Element | null;
  additionalSvgId?: string;
}) => {
  const [hoverElementInfo, setHoverElementInfo] = useState<THoverElement>(null);

  const chartContainerId = `chart-container-id-${additionalSvgId}`;

  const finalColors = useMemo(() => {
    if (colors) return colors;

    let i = data.length;
    const generatedColors: TChartColorItem[] = [];
    const opacityStep = Math.ceil((1 / (data.length + 1)) * 255);

    while (i > 0) {
      generatedColors.push({
        name: data[data.length - i].name,
        value: `${colorConsts.primary[500]}${(opacityStep * i).toString(16)}`,
      });
      i--;
    }

    return generatedColors;
  }, [data, colors]);

  useEffect(() => {
    renderChart(
      chartSvgWidth,
      data,
      finalColors,
      hoverElementInfo,
      hasTooltip ? setHoverElementInfo : () => {},
      chartPadding,
      chartShadowPadding,
      chartContainerId,
      onChartSectionClick,
      hoverTooltipSide,
    );
  }, [data, chartSvgWidth, finalColors, hoverElementInfo, hoverTooltipSide]);

  return (
    <VStack>
      {titleElement}

      {/* Chart Content (chart and overlayed elements) */}
      <VStack position={'relative'}>
        <HStack gap={chartAndLegendGap}>
          <VStack
            background={chartBackgroundColor}
            borderRadius={'50%'}
            boxShadow={hasShadow ? '0px 6px 34px 0px #00417933' : 'none'}
            position={'relative'}
            onMouseLeave={() => setHoverElementInfo(null)} // a small hack to be sure we cleared the hover-tooltip element
          >
            {/* Overlayed elements */}
            <Center
              position={'absolute'}
              width={'100%'}
              height={'100%'}
              zIndex={0}
              top={0}
              visibility={centeredElement || hoverElementInfo ? 'visible' : 'hidden'}
            >
              {centeredElement && (
                <Center width={'50%'} height={'50%'}>
                  {centeredElement}
                </Center>
              )}
            </Center>

            {hoverElementInfo &&
              (tooltipElementConfig?.hasValue ||
                tooltipElementConfig?.hasDescription ||
                tooltipElementConfig?.hasText) && (
                <VStack
                  position={'absolute'}
                  left={`${hoverElementInfo.x + hoverCorrection}px`}
                  top={`${hoverElementInfo.y}px`}
                  width={tooltipElementConfig.width}
                  backgroundColor={'white'}
                  borderRadius={'8px'}
                  boxShadow={'0px 6px 34px 0px #00417933'}
                  justifyContent={'flex-start'}
                  padding={'10px'}
                  zIndex={2}
                >
                  {tooltipElementConfig?.hasValue && (
                    <Text color={hoverElementInfo.color} variant={'urbanistBold'} fontSize={'20px'} width={'100%'}>
                      {hoverElementInfo.value}%
                    </Text>
                  )}
                  {tooltipElementConfig?.hasText && (
                    <Text variant={'urbanistSemiBold'} width={'100%'} textAlign={'start'}>
                      {hoverElementInfo.text}
                    </Text>
                  )}
                  {tooltipElementConfig?.hasDescription && (
                    <Text variant={'urbanistExtraBold'} width={'100%'} color={'text.mediumGray'}>
                      {hoverElementInfo.description}
                    </Text>
                  )}
                </VStack>
              )}

            {/* Chart */}
            <div style={{ padding: `${chartPadding}px`, zIndex: 1 }} id={chartContainerId}></div>
          </VStack>

          {/* Legend Content */}
          {hasLegend && (
            <VStack gap={legendItemsVerticalGap}>
              {data.map((item) => {
                const foundDotColor = finalColors?.find((color) => color.name === item.name)?.value;
                const isAnySectionHovered = !!hoverElementInfo;
                const isThisItemHovered = hoverElementInfo?.color === foundDotColor;
                const legendDotColor = !isAnySectionHovered || isThisItemHovered ? foundDotColor : `${foundDotColor}80`;
                return (
                  <HStack key={item.name} width={'100%'}>
                    <Flex
                      borderRadius={'50%'}
                      minWidth={legendDotAndTextSize}
                      minHeight={legendDotAndTextSize}
                      background={legendDotColor}
                    ></Flex>
                    {legendLabelIncludesValue ? (
                      <HStack width="100%">
                        <Text
                          variant={'urbanistSmallBold'}
                          fontSize={legendDotAndTextSize}
                          color={
                            isThisItemHovered || !isAnySectionHovered
                              ? themeColors.text.mediumGray
                              : `${themeColors.text.mediumGray}80`
                          }
                        >
                          {item.name}
                          <span
                            style={{
                              marginLeft: '7px',
                              color:
                                isThisItemHovered || !isAnySectionHovered
                                  ? themeColors.text.darkBlue
                                  : themeColors.text.mediumGray,
                            }}
                          >
                            {item.value}
                          </span>
                        </Text>
                      </HStack>
                    ) : (
                      <Text
                        variant={'urbanistBold'}
                        fontSize={legendDotAndTextSize}
                        color={isThisItemHovered || !isAnySectionHovered ? 'text.blueGray' : 'text.mediumGray'}
                        fontWeight={600}
                        marginLeft={'6px'}
                      >
                        {item.name}
                      </Text>
                    )}
                    {/* {itemTypeToIcon(item.itemType)} */}
                  </HStack>
                );
              })}
            </VStack>
          )}
        </HStack>
      </VStack>

      {/* Footer */}
      <Flex width={'100%'}>
        <VStack maxWidth={chartSvgWidth + CHART_PADDING * 2 + chartShadowPadding * 2}>{footerElement}</VStack>
      </Flex>
    </VStack>
  );
};

export default DoughnutChart;
