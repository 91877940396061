import ModalContainer from '@/components/molecules/ModalContainer';
import { Center, Input, Text, VStack } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppStore } from '@/store/useAppStore';
import useToast from '@/hooks/useToast';
import { TUserContactMemberCareForm } from '@/types/User.types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import EmailJS from '@emailjs/browser';
import CustomButton from '@/components/atoms/CustomButton';
import svgIcons from '@/assets/svg/icons';
import colors from '@/theme/colors';
import { FORM_FIELD_WIDTH } from '@/constants/dimensions';

interface Props {
  toEmail: string;
  toEmailName: string;
  triggerButtonLabel?: string;
  triggerButtonWidth?: string;
  backgroundColor?: string;
  labelColor?: string;
  hoveredBackgroundColor?: string;
  hoveredLabelColor?: string;
  triggerButtonTransparent?: boolean;
  noShadow?: boolean;
}

const SendEmailModalWithTrigger = ({
  toEmail,
  toEmailName,
  triggerButtonLabel = 'Send Email',
  triggerButtonWidth = '340px',
  backgroundColor = colors.extra.white,
  labelColor = colors.primary[500],
  hoveredBackgroundColor = colors.primary[500],
  hoveredLabelColor = colors.extra.white,
  triggerButtonTransparent = false,
  noShadow = false,
}: Props) => {
  const { user } = useAppStore();
  const toast = useToast();

  const emailRef = useRef<HTMLAnchorElement>(null);

  const [sendEmailModalVisible, setSendEmailModalVisible] = useState(false);

  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const { register, formState, reset, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(
      yup.object().shape({
        emailSubject: yup.string().required('Subject is required.').min(5, 'Subject too short'),
        emailContent: yup.string().required('Content is required.').min(10, 'Content too short'),
      }),
    ),
  });

  const { isValid, errors, touchedFields } = formState;

  const [customEmailError, setCustomEmailError] = useState('');

  const onFormSubmit = async (formData: TUserContactMemberCareForm) => {
    setIsSendingEmail(true);
    try {
      const fromEmail = user?.email ?? user?.user_metadata.email ?? '';

      let memberProfileBaseUrl = '';

      const appEnv = import.meta.env.VITE_SENTRY_ENV;

      switch (appEnv) {
        case 'production':
          memberProfileBaseUrl = 'https://backoffice.myomnia.health';
          break;

        case 'staging':
          memberProfileBaseUrl = 'https://staging.backoffice.myomnia.health';
          break;

        case 'development':
        default:
          memberProfileBaseUrl = 'https://dev.backoffice.myomnia.health';
          break;
      }

      await EmailJS.send(
        import.meta.env.VITE_EMAIL_SERVICE_ID,
        import.meta.env.VITE_EMAIL_TEMPLATE_ID,
        {
          emailContent: formData.emailContent,
          emailSubject: formData.emailSubject,
          fromEmail,
          fromName: user?.user_metadata.firstName ?? user?.firstName,
          toEmail,
          memberProfileUrl: `${memberProfileBaseUrl}/member-care/members/${user?.user_id}`,
        },
        { publicKey: import.meta.env.VITE_SENDGRID_PUBLIC_KEY },
      );

      setSendEmailModalVisible(false);
      reset();
      setCustomEmailError('');

      toast({
        title: 'Email Sent',
        description: 'Member Care contacted successfully',
        status: 'success',
      });
    } catch (e: any) {
      setCustomEmailError(e.message ?? e.text ?? e.code ?? e);
    } finally {
      setIsSendingEmail(false);
    }
  };

  return (
    <>
      <a ref={emailRef} href={`mailto:${toEmail}`} style={{ display: 'none' }}></a>
      <CustomButton
        isTransparent={triggerButtonTransparent}
        label={triggerButtonLabel}
        onClick={() => setSendEmailModalVisible(true)}
        backgroundColor={backgroundColor}
        labelColor={labelColor}
        buttonType={isValid ? 'submit' : 'button'}
        width={triggerButtonWidth}
        style={{ border: `2px solid ${triggerButtonTransparent ? labelColor : backgroundColor}` }}
        hoverBackgroundColor={hoveredBackgroundColor}
        labelHoverColor={hoveredLabelColor}
        iconBeforeLabelSvgPath={svgIcons.email}
        iconBeforeLabelSvgColor={labelColor}
        noShadow={noShadow}
      />
      <ModalContainer
        isOpen={sendEmailModalVisible}
        onClose={() => setSendEmailModalVisible(false)}
        isBlur={false}
        width={'420px'}
        height={'20vh'}
        backgroundColor={'background.lightBlue'}
      >
        <Text variant={'loraTitle'} textAlign={'center'} fontSize={'26px'} marginTop={'30px'} paddingX={'40px'}>
          Send {toEmailName} an Email
        </Text>
        <Text variant={'urbanistMediumLarge'} textAlign={'center'} color={'text.mediumGray'} marginTop={'20px'}>
          Enter email subject and email content below.
        </Text>
        <Center>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <VStack marginY={'40px'} width={FORM_FIELD_WIDTH} gap={5}>
              <Input
                {...register('emailSubject', {
                  required: true,
                })}
                className="custom-input"
                placeholder={'Email Subject'}
                _placeholder={{
                  fontWeight: '600',
                  color: 'background.blueGray',
                }}
                data-state={touchedFields.emailSubject ? (errors.emailSubject?.message ? 'invalid' : 'valid') : ''}
              />
              {errors.emailSubject && <Text variant={'error'}>{errors.emailSubject.message}</Text>}
              <Input
                {...register('emailContent', {
                  required: true,
                })}
                className="custom-input"
                placeholder={'Email Content'}
                _placeholder={{
                  fontWeight: '600',
                  color: 'background.blueGray',
                }}
                data-state={touchedFields.emailContent ? (errors.emailContent?.message ? 'invalid' : 'valid') : ''}
              />
              {errors.emailContent && <Text variant={'error'}>{errors.emailContent.message}</Text>}
              {customEmailError && <Text variant={'error'}>{customEmailError}</Text>}
              <CustomButton
                style={{ marginTop: '20px' }}
                label="Send Email"
                backgroundColor="primary.500"
                width={FORM_FIELD_WIDTH}
                labelColor="white"
                disabled={!isValid}
                isLoading={isSendingEmail}
                buttonType={isValid ? 'submit' : 'button'}
              />
            </VStack>
          </form>
        </Center>
      </ModalContainer>
    </>
  );
};

export default SendEmailModalWithTrigger;
