import { TPeerSupportDashboard } from '@/types/PeerSupport.types';
import { HStack, Text, Tooltip, VStack } from '@chakra-ui/react';
import { format, formatDate } from 'date-fns';
import { useState } from 'react';

const MAX_BAR_HEIGHT = 140;
const BAR_WIDTH = 32;

const PeerSupportDashboardHistoricalChart = ({
  data,
  onBarClick,
  selectedMonth,
}: {
  data: TPeerSupportDashboard['historicalData'];
  onBarClick: (month: string) => void;
  selectedMonth?: string;
}) => {
  const highestEventCount = Math.max(...data.map((monthlyData) => monthlyData.eventCount));

  const [isChartHovered, setIsChartHovered] = useState(false);
  const [hoveredBar, setHoveredBar] = useState('');

  return (
    <HStack gap={'20px'} onMouseEnter={() => setIsChartHovered(true)} onMouseLeave={() => setIsChartHovered(false)}>
      {data.map((monthlyData) => {
        const isMonthSelected = monthlyData.month === selectedMonth;
        const isNotSelectable = monthlyData.eventCount === 0 || isMonthSelected;
        const splitDate = monthlyData.month.split('-');
        const dateToDisplay = parseInt(splitDate[1]) < 10 ? `${splitDate[0]}-0${splitDate[1]}` : monthlyData.month;

        return (
          <VStack key={monthlyData.month} opacity={isChartHovered ? (monthlyData.month === hoveredBar ? 1 : 0.5) : 1}>
            <VStack height={`${MAX_BAR_HEIGHT}px`} justifyContent={'flex-end'}>
              <Tooltip
                label={`${isMonthSelected ? 'Showing' : 'Click to show'} data for ${formatDate(new Date(dateToDisplay), 'MMMM')}`}
                padding={'8px'}
                borderRadius={'6px'}
                color={'text.darkBlue'}
                backgroundColor={'extra.white'}
                boxShadow={'0px 2px 8px 0px #00417933'}
                placement="right-end"
                cursor={isNotSelectable ? 'default' : 'pointer'}
              >
                <VStack
                  backgroundColor={isMonthSelected ? 'secondary.500' : 'primary.500'}
                  borderRadius={'10px'}
                  height={`${(MAX_BAR_HEIGHT * monthlyData.eventCount) / highestEventCount}px`}
                  width={`${BAR_WIDTH}px`}
                  onMouseEnter={() => setHoveredBar(monthlyData.month)}
                  cursor={isNotSelectable ? 'default' : 'pointer'}
                  onClick={() => (isNotSelectable ? {} : onBarClick(monthlyData.month))}
                  boxShadow={isMonthSelected ? '3px 1px 10px 1px #0E006264' : ''}
                />
              </Tooltip>
            </VStack>
            <HStack width={`${BAR_WIDTH}px`}>
              <Text
                variant={'urbanistSmallBold'}
                color={isMonthSelected ? 'text.darkBlue' : 'text.mediumGray'}
                fontWeight={isMonthSelected ? 900 : 700}
              >
                {format(dateToDisplay, 'MMM')}
              </Text>
              <Text variant={'urbanistSmallBold'} color={'text.darkBlue'} fontWeight={isMonthSelected ? 900 : 700}>
                {monthlyData.eventCount}
              </Text>
            </HStack>
          </VStack>
        );
      })}
    </HStack>
  );
};

export default PeerSupportDashboardHistoricalChart;
