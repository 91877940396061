import colors from '@/theme/colors';

export const BACKGROUND_COLOR = '#F0F8FF';

export const spinAnimation = `spin infinite 2s linear`;

export const BUTTON_BOX_SHADOW_STYLE =
  '0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03)';

export const CHART_COLORS_ARRAY = [
  colors.primary[500],
  colors.secondary[500],
  colors.extra.yellowStrong,
  colors.extra.teal,
  colors.extra.skyBlue,
  colors.extra.purplePink,
  colors.extra.turquoise,
  colors.extra.washedOutPurple,
  colors.extra.neonPink,
  colors.extra.grassGreen,
];
