import ModalContainer from '@/components/molecules/ModalContainer';
import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useTelemetry from '@/providers/TelemetryProvider/useTelemetry';
import { Center, Image, Input, Text, VStack } from '@chakra-ui/react';
import { FORM_FIELD_WIDTH } from '@/constants/dimensions';
import iconsPng from '@/assets/img/png/icons';
import CustomButton from '@/components/atoms/CustomButton';
import { TRosterUserPeerSupportCertificateInfo } from '@/types/Roster.types';
import DateInput from '@/components/atoms/DateInput';
import useUpgradeMemberToPeerSupport from '@/hooks/peersupport/upgradeMemberToPeerSupport';
import { addDays, isBefore } from 'date-fns';

interface Props {
  peerSupportModalVisible: boolean;
  setPeerSupportModalVisible: Dispatch<SetStateAction<boolean>>;
  organizationId: string;
  userToUpgradeToPeerSupportId: string;
  itemToUpgradeToPeerSupportName: string;
  reFetch: () => void;
}

const addPeerSupportCertificationSchema = yup
  .object()
  .shape({
    dateOfIssue: yup.string().required('Date of Issue is required.'),
    validUntil: yup.string().required('Valid Until is required.'),
    certificateId: yup.string().required('Certificate ID is required.'),
  })
  .required();

const OrganizationRosterPeerSupportModal = ({
  peerSupportModalVisible,
  setPeerSupportModalVisible,
  organizationId,
  userToUpgradeToPeerSupportId,
  itemToUpgradeToPeerSupportName,
  reFetch,
}: Props) => {
  const { trackEvent } = useTelemetry();

  const {
    register,
    getValues,
    reset: resetUserForm,
    formState,
    watch,
    setValue,
  } = useForm<TRosterUserPeerSupportCertificateInfo>({
    resolver: yupResolver(addPeerSupportCertificationSchema),
    mode: 'all',
    defaultValues: {
      dateOfIssue: '',
      validUntil: '',
      certificateId: '',
    },
  });

  const { isValid, errors, touchedFields } = formState;

  const dateOfIssue = watch('dateOfIssue');
  const validUntil = watch('validUntil');

  const dateOfIssueAfterOneDay = dateOfIssue ? addDays(new Date(dateOfIssue), 1) : undefined;

  useEffect(() => {
    if (validUntil && dateOfIssueAfterOneDay) {
      const validUntilDate = new Date(validUntil);
      if (isBefore(validUntilDate, dateOfIssueAfterOneDay)) {
        setValue('validUntil', dateOfIssueAfterOneDay.toISOString().split('T')[0]);
      }
    }
  }, [validUntil, dateOfIssueAfterOneDay]);

  const { isPending, mutate: mutateEmployeeToPeerSupport } = useUpgradeMemberToPeerSupport(() => {
    reFetch();
    resetUserForm();
    setPeerSupportModalVisible(false);
  });

  const upgradeMemberToPeerSupport = useCallback(async () => {
    const peerSupportUpgradeInfo: TRosterUserPeerSupportCertificateInfo = getValues();
    mutateEmployeeToPeerSupport({ organizationId, userId: userToUpgradeToPeerSupportId, peerSupportUpgradeInfo });
  }, [getValues, trackEvent, reFetch, organizationId, userToUpgradeToPeerSupportId]);

  return (
    <ModalContainer
      isOpen={peerSupportModalVisible}
      onClose={() => setPeerSupportModalVisible(false)}
      isBlur={false}
      width={'40%'}
      height={'20vh'}
      backgroundColor={'background.lightBlue'}
    >
      <Text variant={'loraTitle'} textAlign={'center'} fontSize={'26px'} marginTop={'20px'} whiteSpace={'pre-wrap'}>
        Upgrade Member to Peer Support
      </Text>
      <Center marginTop={'20px'}>
        <Image alt="add-member" src={iconsPng.handshake} width={120} height={120} />
      </Center>
      <VStack marginTop={'40px'}>
        <Text variant={'urbanistSemiBoldLarge'} color={'text.mediumBlue'} maxWidth={'300px'}>
          Add information about {itemToUpgradeToPeerSupportName}’s certification below.
        </Text>
      </VStack>
      <Center>
        <VStack marginY={'40px'} width={FORM_FIELD_WIDTH} gap={'20px'}>
          <DateInput
            title="Date of Issue"
            value={dateOfIssue}
            registerFormField={register}
            registerFormFieldName="dateOfIssue"
            errorMessage={errors.dateOfIssue?.message}
          />

          <DateInput
            title="Valid Until"
            value={validUntil}
            minValue={dateOfIssueAfterOneDay?.toISOString().split('T')[0]}
            registerFormField={register}
            registerFormFieldName="validUntil"
            errorMessage={errors.validUntil?.message}
          />

          <Input
            {...register('certificateId', { required: true })}
            className="custom-input"
            placeholder={'Certificate ID'}
            type="text"
            _placeholder={{
              fontWeight: '600',
              color: 'background.blueGray',
              fontSize: '14px',
            }}
            data-state={touchedFields.certificateId ? (errors.certificateId?.message ? 'invalid' : 'valid') : ''}
          />

          {errors.certificateId && <Text variant={'error'}>{errors.certificateId.message}</Text>}
          <CustomButton
            style={{ marginTop: '20px' }}
            backgroundColor="primary.500"
            label="Upgrade Member"
            width={FORM_FIELD_WIDTH}
            labelColor="white"
            onClick={upgradeMemberToPeerSupport}
            disabled={!isValid}
            isLoading={isPending}
          />
        </VStack>
      </Center>
    </ModalContainer>
  );
};

export default OrganizationRosterPeerSupportModal;
