import DateInput from '@/components/atoms/DateInput';
import Dropdown from '@/components/atoms/Dropdown';
import DropdownWithSections from '@/components/atoms/DropdownWithSections';
import ModalContainer from '@/components/molecules/ModalContainer';
import {
  PEER_SUPPORT_EVENT_OUTCOME,
  PEER_SUPPORT_EVENT_OUTCOME_DISPLAY_LABELS,
  WELLNESS_DOMAINS_DISPLAY_LABELS,
  WELLNESS_DOMAINS_LIST,
  WELLNESS_SUB_DOMAINS_DISPLAY_LABELS,
  WELLNESS_SUBDOMAINS_PER_DOMAIN,
} from '@/constants';
import { THealthDomain, THealthDomainWithSubDomains } from '@/types/Health.types';
import { TPeerSupportEventOutcome } from '@/types/PeerSupport.types';
import { getWellnessDomainLabelFromName } from '@/utils/getWellnessDomainLabelFromName';
import { Text, useDisclosure, VStack } from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CustomButton from '@/components/atoms/CustomButton';
import { FORM_FIELD_WIDTH } from '@/constants/dimensions';
import useCreateOutreachEvent from '@/hooks/organization/createOutreachEvent';
import { useAppStore } from '@/store/useAppStore';
import svgIcons from '@/assets/svg/icons';
import colors from '@/theme/colors';

interface Props {
  isOpen: boolean;
  setIsOpen: (_: boolean) => void;
}

const createOutreachSchema = yup
  .object()
  .shape({
    startDate: yup.string().required('Contact Date is required.'), // was Start Date
    outcome: yup.string().oneOf(PEER_SUPPORT_EVENT_OUTCOME).required('Outcome is required'),
  })
  .required();

const PeerSupportNewOutreachModal = ({ isOpen, setIsOpen }: Props) => {
  const { user } = useAppStore();

  const {
    register,
    getValues,
    setValue,
    formState,
    watch,
    reset: resetOutreachForm,
  } = useForm({
    resolver: yupResolver(createOutreachSchema),
    mode: 'all',
    defaultValues: {
      startDate: '',
    },
  });
  const { isValid, errors } = formState;

  const startDate = watch('startDate');
  const outcomeValue = watch('outcome');

  const [selectedWellnessDomains, setSelectedWellnessDomains] = useState<Set<THealthDomain>>(new Set());

  const updateSelectedWellnessDomains = useCallback((newOption: THealthDomain) => {
    setSelectedWellnessDomains((prev) => {
      const newSelectedWellnessDomains = new Set(prev);
      if (newSelectedWellnessDomains.has(newOption)) {
        newSelectedWellnessDomains.delete(newOption);
      } else {
        newSelectedWellnessDomains.add(newOption);
      }
      return newSelectedWellnessDomains;
    });
  }, []);

  const domainDropdownDisclosure = useDisclosure();
  const subdomainDropdownDisclosure = useDisclosure();
  const outcomeDropdownDisclosure = useDisclosure();

  const [selectedWellnessSubDomains, setSelectedWellnessSubDomains] = useState<Partial<THealthDomainWithSubDomains>>();

  const updateSelectedWellnessSubDomains = useCallback((domain: string, subdomain: string) => {
    setSelectedWellnessSubDomains((prev) => {
      const newSelectedWellnessSubDomains = { ...prev };

      const foundSubdomainIndex = newSelectedWellnessSubDomains[domain as THealthDomain]?.findIndex(
        (selection) => selection === subdomain,
      );

      if (foundSubdomainIndex !== undefined && foundSubdomainIndex >= 0) {
        newSelectedWellnessSubDomains[domain as THealthDomain] = newSelectedWellnessSubDomains[
          domain as THealthDomain
        ]?.filter((foundSubdomain) => foundSubdomain !== subdomain);
      } else {
        newSelectedWellnessSubDomains[domain as THealthDomain] = [
          ...(newSelectedWellnessSubDomains[domain as THealthDomain] ?? []),
          subdomain,
        ];
      }
      return newSelectedWellnessSubDomains;
    });
  }, []);

  // removes all selections of a subdomains from a domain that is no longer selected
  useEffect(() => {
    setSelectedWellnessSubDomains((prev) => {
      if (prev && Object.keys(prev).length === selectedWellnessDomains.size) {
        return Object.fromEntries(
          Object.entries(prev).filter(([key]) => selectedWellnessDomains.has(key as THealthDomain)),
        );
      } else {
        const domainsKeysArray = Array.from(selectedWellnessDomains.keys());
        return Object.fromEntries(domainsKeysArray.map((domainKey) => [domainKey, prev?.[domainKey] ?? []]));
      }
    });
  }, [selectedWellnessDomains.size]);

  const subDomainOptions = useMemo(() => {
    const options: any = {};
    Object.entries(WELLNESS_SUBDOMAINS_PER_DOMAIN).forEach(([domain, subdomains]) => {
      if (selectedWellnessDomains.has(domain as THealthDomain)) {
        options[domain] = subdomains;
      }
    });

    return options as typeof WELLNESS_SUBDOMAINS_PER_DOMAIN;
  }, [selectedWellnessDomains]);

  const isSubmitDisabled = useMemo(
    () =>
      !isValid ||
      selectedWellnessDomains.size === 0 ||
      !selectedWellnessSubDomains ||
      Object.values(selectedWellnessSubDomains ?? {}).some((subdomain) => subdomain.length === 0),
    [isValid, selectedWellnessDomains, selectedWellnessSubDomains],
  );

  const { mutate, isPending } = useCreateOutreachEvent(() => {
    resetOutreachForm();
    setSelectedWellnessDomains(new Set());
    setSelectedWellnessSubDomains(undefined);

    setIsOpen(false);
  });

  const onFormSubmit = () => {
    const formValues = getValues();

    const outreachSubjectToBe = selectedWellnessSubDomains
      ? Object.entries(selectedWellnessSubDomains).map(([domain, subdomains]) => {
          return {
            domain: domain as THealthDomain,
            subdomains,
          };
        })
      : [];

    mutate({
      organizationId: user?.app_metadata.organizationId ?? 'no-org-id',
      type: 'PEER_SUPPORT_OUTREACH',
      data: { subject: outreachSubjectToBe, outcome: formValues.outcome, startDate: formValues.startDate },
    });
  };

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      isBlur={false}
      width="420px"
      backgroundColor="white"
      scrollOverflow={false}
    >
      <VStack padding={'20px'} gap={'30px'} minHeight={'100%'} overflowY={'scroll'}>
        <Text variant={'loraSmallTitle'} marginBottom={'30px'} fontWeight={500}>
          New Outreach
        </Text>

        <DateInput
          title="Contact Date" // was Start Date
          value={startDate}
          registerFormField={register}
          registerFormFieldName="startDate"
          errorMessage={errors.startDate?.message}
        />

        <Dropdown<THealthDomain>
          disclosure={domainDropdownDisclosure}
          title="Subject(s) of Outreach"
          options={WELLNESS_DOMAINS_LIST}
          optionLabelDisplay={(option) => `${WELLNESS_DOMAINS_DISPLAY_LABELS[option as THealthDomain]}`}
          selectedOptions={Array.from(selectedWellnessDomains.values())}
          onOptionClick={(option) => updateSelectedWellnessDomains(option)}
          onBottomCloseButtonClick={subdomainDropdownDisclosure.onOpen}
        />

        <DropdownWithSections<THealthDomain>
          disclosure={subdomainDropdownDisclosure}
          title="Secondary Domain(s)"
          onSectionOptionClick={updateSelectedWellnessSubDomains}
          sections={subDomainOptions}
          selectedSectionsOptions={selectedWellnessSubDomains}
          sectionLabelDisplay={getWellnessDomainLabelFromName}
          sectionOptionLabelDisplay={(subdomain: string) => WELLNESS_SUB_DOMAINS_DISPLAY_LABELS[subdomain]}
          noOptionsTooltip="Please select at least one of the Subjects above"
          onBottomCloseButtonClick={outcomeDropdownDisclosure.onOpen}
        />

        <Dropdown<TPeerSupportEventOutcome>
          disclosure={outcomeDropdownDisclosure}
          title="Outcome"
          options={PEER_SUPPORT_EVENT_OUTCOME}
          selectedOptions={outcomeValue ? [outcomeValue as TPeerSupportEventOutcome] : []}
          onOptionClick={(option) => setValue('outcome', option, { shouldValidate: true })}
          optionLabelDisplay={(outcome: string) =>
            PEER_SUPPORT_EVENT_OUTCOME_DISPLAY_LABELS[outcome as TPeerSupportEventOutcome]
          }
          bottomSvgIconPath={svgIcons.circledCheckMark}
          bottomSvgIconSize={25}
          bottomSvgIconColor={colors.secondary[500]}
        />

        <VStack marginY={'20px'}>
          <CustomButton
            width={FORM_FIELD_WIDTH}
            label="Save"
            onClick={onFormSubmit}
            backgroundColor="primary.500"
            hoverBackgroundColor="primary.600"
            labelColor="white"
            labelHoverColor="white"
            disabled={isSubmitDisabled}
            disabledColor="primary.400"
            isLoading={isPending}
          />
        </VStack>
      </VStack>
    </ModalContainer>
  );
};

export default PeerSupportNewOutreachModal;
