import { createContext, useCallback, useMemo } from 'react';
import Cookies from 'js-cookie';
import { api } from '@/services/api';
import { useQuery } from '@tanstack/react-query';
import { isUserWithData } from '@/utils/isUserWithData';
import { useUserOnboardingStore } from '@/store/useUserOnboardingStore';
import { signInPost, userGet } from '@/services/api/requests/user';
import COOKIE_KEYS from '@/constants/cookies';
import { TUser } from '@/types/User.types';
import roles from '@/constants/roles';
import { myOrganizationGet } from '@/services/api/requests/organization';
import { lastScoresGet } from '@/services/api/requests/result';
import { useAppStore } from '@/store/useAppStore';
import { useOrganizationOnboardingStore } from '@/store/useOrganizationOnboardingStore';
import queryKeys from '@/constants/queryKeys';
import { acceptedDocumentsGet } from '@/services/api/requests/legal';
import { TLegalDocumentAcceptanceStatus } from '@/types/Legal.types';
import { THealthDomainScores } from '@/types/Health.types';
import useTelemetry from '@/providers/TelemetryProvider/useTelemetry';
import LoadingPage from '@/components/templates/LoadingPage';
import { useScreenerStore } from '@/store/useScreenerStore';

export type TAuthContext = {
  user: TUser | null;
  isAuthenticated: boolean;
  isAdmin: boolean;
  isPeerSupport: boolean;
  isUserWithDataNotSet: boolean;
  isUserWithDataSet: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => void;
};

export const AuthContext = createContext<TAuthContext | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { user, setAppStoreOnSignIn } = useAppStore();

  const { handleSignIn: handleSignInTelemetry, handleSignOut: handleSignOutTelemetry } = useTelemetry();

  const isAuthenticated = !!user;
  const isAdmin = !!user?.roles.includes(roles.orgAdmin);
  const isPeerSupport = !!user?.roles.includes(roles.peerSupport);

  const isUserWithDataSet = isUserWithData(user);
  const isUserWithDataNotSet = !isUserWithDataSet && !isAdmin;

  const handleUserDataFlow = async () => {
    const requests = [];

    const userData = (await userGet()) as TUser;

    const allowedRoles = [roles.orgAdmin, roles.orgEmployee];
    const userRoles = userData.roles;
    const userAllowedToSignIn = allowedRoles.some((role) => userRoles.includes(role));

    if (!userAllowedToSignIn) {
      // toast({
      //   status: 'error',
      //   title: 'Wrong Platform',
      //   description: 'Please proceed to https://backoffice.myomnia.health',
      // });

      signOut();

      throw Error('Wrong platform. Please proceed to: https://backoffice.myomnia.health');
    }

    requests.push(lastScoresGet());
    requests.push(acceptedDocumentsGet());

    const responses = await Promise.all(requests);

    const scoresResponse = responses[0] as THealthDomainScores;
    const eulaResponse = responses[1] as TLegalDocumentAcceptanceStatus[];

    let organizationResponse = null;

    const isOrgAdmin = userData.roles.includes(roles.orgAdmin);
    if (isOrgAdmin) {
      organizationResponse = await myOrganizationGet();
    }

    setAppStoreOnSignIn(userData, scoresResponse, eulaResponse, organizationResponse);

    handleSignInTelemetry(userData.user_id);
  };

  const signIn = useCallback(async (email: string, password: string) => {
    await signInPost(email, password);

    await handleUserDataFlow();
  }, []);

  const { isPending } = useQuery({
    queryKey: [queryKeys.auth.user],
    queryFn: async () => {
      // Get token form cookie if exists
      const token = Cookies.get(COOKIE_KEYS.ACCESS_TOKEN);

      if (!token) {
        return null;
      }

      // Add token to api headers
      api.defaults.headers.Authorization = token;

      await handleUserDataFlow();
    },
  });

  const signOut = useCallback(async () => {
    Cookies.remove(COOKIE_KEYS.ACCESS_TOKEN);
    api.defaults.headers.Authorization = '';

    handleSignOutTelemetry(user?.user_id ?? 'id-not-provided');

    /**
     * On logout reset all persisted stores. If more stores are added, we can reset them in one go
     * see https://zustand.docs.pmnd.rs/guides/how-to-reset-state for more details if needed
     */
    useUserOnboardingStore.getState().reset();
    useOrganizationOnboardingStore.getState().reset();
    useAppStore.getState().reset();
    useScreenerStore.getState().reset();

    // queryClient.removeQueries({}); // probably isn't needed and is causing a weird page/error reset
  }, []);

  const auth = useMemo(
    () => ({
      isAuthenticated,
      user,
      isUserWithDataNotSet,
      isUserWithDataSet,
      isAdmin,
      isPeerSupport,
      signIn,
      signOut,
    }),
    [isAuthenticated, user, signIn, signOut, isUserWithDataNotSet, isUserWithDataSet, isAdmin, isPeerSupport],
  );

  return <AuthContext.Provider value={auth}>{isPending ? <LoadingPage /> : children}</AuthContext.Provider>;
};
