import queryKeys from '@/constants/queryKeys';
import { peerSupportDashboardGet } from '@/services/api/requests/peer-support';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

const usePeerSupportDashboard = (month: string) => {
  const { isLoading, data, isError, error, isRefetching, refetch } = useQuery({
    queryKey: [queryKeys.peerSupport.dashboard, month],
    staleTime: 0,
    placeholderData: keepPreviousData,
    queryFn: async () => {
      const response = await peerSupportDashboardGet(month);

      return response;
    },
  });

  return { isLoading, data, isError, error, isRefetching, refetch };
};

export default usePeerSupportDashboard;
