import CustomButton from '@/components/atoms/CustomButton';
import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import { TUserMetadata } from '@/types/User.types';
import { Box, HStack, Image, Text, VStack } from '@chakra-ui/react';
import UserContactDetailsCard from './components/UserContactDetailsBox';
import UserDemographicsCard from './components/UserDemographicsBox';
import UserProfilePhotoBox from './components/UserProfilePhotoBox';
import UserProfileBox from './components/UserProfileBox';
import roles from '@/constants/roles';
import colors from '@/theme/colors';
import iconsPng from '@/assets/img/png/icons';

interface UserProfileOverviewProps {
  title: string;
  updateProfilePhoto: (photo: File) => Promise<boolean>;
  userData: TUserMetadata;
  onClickEditContactDetails: () => void;
  onClickEditDemographics: () => void;
  onPhotoChange?: (photo: File) => void;
  onClickProceed?: () => void;
  onClickEditPeerSupportBio?: () => void;
  isLoading?: boolean;
  initialPreview?: string;
  hideEditContactButton?: boolean;
  hideEditDemographicsButton?: boolean;
  userRoles?: string[];
}

const UserProfileOverview = ({
  title,
  updateProfilePhoto,
  userData,
  onClickEditContactDetails,
  onClickEditDemographics,
  onClickProceed,
  onClickEditPeerSupportBio,
  isLoading,
  initialPreview: initialPreviewUrl,
  hideEditContactButton = false,
  hideEditDemographicsButton = false,
  userRoles,
}: UserProfileOverviewProps) => {
  return (
    <VStack width={MAX_CONTAINER_WIDTH}>
      <Box
        position="absolute"
        backgroundImage={'/img/png/signup-top-right-background.png'}
        backgroundSize="cover"
        width="250px"
        height="30vh"
        right={0}
        top={0}
      ></Box>
      <Text variant={'loraTitle'} fontSize={'26px'} fontStyle={'oblique'} color={'primary.veryDarkBlue'}>
        {title}
      </Text>

      <HStack gap="40px">
        <UserProfilePhotoBox updateProfilePhoto={updateProfilePhoto} initialPreviewUrl={initialPreviewUrl} />

        {/* Peer Support Bio Modal */}
        {userRoles?.includes(roles.peerSupport) && (
          <UserProfileBox style={{ height: '270px' }}>
            <HStack justifyContent={'space-between'}>
              <Text variant={'urbanistSemiBoldTitle'} marginBottom={'10px'}>
                Peer Support Biography
              </Text>

              <CustomButton
                onClick={onClickEditPeerSupportBio}
                hoverBackgroundColor={'primary.100'}
                label="Edit"
                labelColor="text.darkBlue"
                style={{
                  padding: 0,
                  borderColor: colors.text.darkBlue,
                  borderWidth: '2px',
                }}
                width={'110px'}
                height={'55px'}
                iconBeforeLabelJSX={
                  <Image
                    alt="edit-icon"
                    src={iconsPng.pen}
                    width={'12px'}
                    height={'12px'}
                    objectFit="cover"
                    style={{ marginRight: '10px' }}
                  />
                }
              />
            </HStack>
            <HStack marginTop={'20px'}>
              <Text
                maxHeight={'150px'}
                overflowY={'scroll'}
                variant={'urbanistSemiBoldRegular'}
                color={'text.mediumBlue'}
              >
                {userData.bio}
              </Text>
            </HStack>
          </UserProfileBox>
        )}
      </HStack>

      <HStack gap="40px" marginBottom={'100px'}>
        <UserContactDetailsCard
          email={userData?.email}
          firstName={userData?.firstName}
          lastName={userData?.lastName}
          phone={userData?.phone}
          onClickEdit={onClickEditContactDetails}
          hideEditButton={hideEditContactButton}
        />

        <UserDemographicsCard
          dateOfBirth={userData?.dateOfBirth}
          heightInches={userData?.heightInches}
          heightFeet={userData?.heightFeet}
          weight={userData?.weight}
          sex={userData?.sex}
          sexOther={userData?.sexOther}
          occupation={userData?.occupation}
          occupationOther={userData?.occupationOther}
          ethnicity={userData?.ethnicity}
          ethnicityOther={userData?.ethnicityOther}
          relationshipStatus={userData?.relationshipStatus}
          relationshipStatusOther={userData?.relationshipStatusOther}
          education={userData?.education}
          educationOther={userData?.educationOther}
          onClickEdit={onClickEditDemographics}
          hideEditButton={hideEditDemographicsButton}
        />
      </HStack>

      {onClickProceed && (
        <CustomButton
          backgroundColor="primary.teal"
          label="Proceed"
          width={'300px'}
          labelColor="white"
          onClick={onClickProceed}
          isLoading={isLoading}
        />
      )}
    </VStack>
  );
};

export default UserProfileOverview;
