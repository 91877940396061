import { useAuthContext } from '@/context/auth/useAuthContext';
import { VStack, Link as ChakraLink, HStack, Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { employeeRoleMenuOptions, orgAdminRoleMenuOptions, peerSupportRoleMenuOptions } from '../options';
import { Link, useRouter } from '@tanstack/react-router';
import menuMessages from '@/messages/menu';

interface Props {
  onPressCallback?: () => void;
}

const ProfileMenu = ({ onPressCallback = () => {} }: Props) => {
  const { isAdmin, isPeerSupport, signOut } = useAuthContext();

  const {
    state: {
      location: { pathname },
    },
    navigate,
  } = useRouter();

  const isOnboarding = pathname.includes('/onboarding');

  // Note: If it is onboarding flo, alow user only to sign out
  const menuOptions = useMemo(() => {
    if (isOnboarding) return [];

    if (isAdmin) return orgAdminRoleMenuOptions;

    if (isPeerSupport) return peerSupportRoleMenuOptions;

    return employeeRoleMenuOptions;
  }, [isOnboarding, isAdmin, isPeerSupport, orgAdminRoleMenuOptions, employeeRoleMenuOptions]);

  const handleSignOut = () => {
    signOut();
    navigate({
      to: '/sign-in',
    });
  };

  return (
    <VStack padding={'20px'} alignItems={'flex-end'}>
      {menuOptions.map((option, index) => {
        const isActiveRoute = pathname === option.navigateTo;
        return (
          <ChakraLink
            as={Link}
            key={`${index}-${option.label}`}
            onClickCapture={() => {
              onPressCallback();
            }}
            to={option.navigateTo}
            textAlign={'right'}
            fontWeight={'600'}
            color={isActiveRoute ? 'primary.500' : 'text.mediumBlue'}
          >
            {option.label}
          </ChakraLink>
        );
      })}
      <HStack
        _hover={{ cursor: 'pointer' }}
        onClick={handleSignOut}
        onClickCapture={() => {
          onPressCallback();
        }}
      >
        <Text color={'text.mediumGray'}>{menuMessages.signOut}</Text>
      </HStack>
    </VStack>
  );
};

export default ProfileMenu;
