import type { CSSProperties } from 'react';

const SvgIconCalendar = ({
  color = '#7C9EBC',
  boxWidth,
  boxHeight,
  size = '16',
  style,
  additionalSvgProps,
}: {
  color: string;
  size?: number | string;
  boxWidth?: number | string;
  boxHeight?: number | string;
  style?: CSSProperties;
  additionalSvgProps?: Record<any, string>;
}) => {
  return (
    <div style={style}>
      <svg
        {...additionalSvgProps}
        width={size}
        height={size}
        viewBox={`0 0 ${boxWidth ?? size} ${boxHeight ?? size}`}
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_15622_30934)">
          <path
            d="M13.6 2.4H12.8V0.8C12.8 0.587827 12.7157 0.384344 12.5657 0.234315C12.4157 0.0842854 12.2122 0 12 0C11.7878 0 11.5843 0.0842854 11.4343 0.234315C11.2843 0.384344 11.2 0.587827 11.2 0.8V2.4H4.8V0.8C4.8 0.587827 4.71571 0.384344 4.56569 0.234315C4.41566 0.0842854 4.21217 0 4 0C3.78783 0 3.58434 0.0842854 3.43431 0.234315C3.28429 0.384344 3.2 0.587827 3.2 0.8V2.4H2.4C1.76348 2.4 1.15303 2.65286 0.702944 3.10294C0.252856 3.55303 0 4.16348 0 4.8V5.6H16V4.8C16 4.16348 15.7471 3.55303 15.2971 3.10294C14.847 2.65286 14.2365 2.4 13.6 2.4Z"
            fill={color}
          />
          <path
            d="M0 13.6002C0 14.2367 0.252856 14.8472 0.702944 15.2973C1.15303 15.7473 1.76348 16.0002 2.4 16.0002H13.6C14.2365 16.0002 14.847 15.7473 15.2971 15.2973C15.7471 14.8472 16 14.2367 16 13.6002V8H0V13.6002Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_15622_30934">
            <rect width="16" height="16" fill={color} />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default SvgIconCalendar;
