import { DEFAULT_ITEMS_PER_PAGE } from '@/constants';
import { TCustomAxiosResponse } from '@/services/api';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

interface IUseInfinitePaginationParams<T> {
  query: (page: number, limit?: number) => Promise<TCustomAxiosResponse<T>>;
  queryKey: string[];
  limit?: number;
}

/**
 *
 * @param query - the query to be executed. Must receive `page: number` param and return TCustomAxiosResponse<T>
 * @returns isLoading: boolean, data: T[], fetchNextPage: () => void, isFetchingNextPage: boolean, hasNextPage: boolean;
 */
export const useInfinitePagination = <T extends any[]>({
  query,
  queryKey,
  limit = DEFAULT_ITEMS_PER_PAGE,
}: IUseInfinitePaginationParams<T>) => {
  const { isLoading, data, fetchNextPage, isFetchingNextPage, hasNextPage } = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam: page }) => {
      const response = await query(page, limit);
      return response;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, __, lastPageParam) => {
      if (lastPage.data.length < limit) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });

  const flattenData = useMemo(() => {
    return data?.pages.flatMap((page) => page.data) || [];
  }, [data?.pages]);

  return { isLoading, data: flattenData, fetchNextPage, isFetchingNextPage, hasNextPage };
};
