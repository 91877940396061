import logoVariants from '@/assets/img/logo';
import iconsPng from '@/assets/img/png/icons';
import svgIcons from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import LoadingLogo from '@/components/atoms/LoadingLogo';
import SvgIcon from '@/components/atoms/SvgIcon';
import DoughnutChart, { TChartColorItem, TChartDataItem } from '@/components/molecules/DoughnutChart';
import ModalContainer from '@/components/molecules/ModalContainer';
import LoadingPage from '@/components/templates/LoadingPage';
import {
  PEER_SUPPORT_EVENT_OUTCOME_DISPLAY_LABELS,
  WELLNESS_DOMAINS_DISPLAY_LABELS,
  WELLNESS_DOMAINS_DISPLAY_LABELS_REVERSE,
  WELLNESS_SUB_DOMAINS_DISPLAY_LABELS,
} from '@/constants';
import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import { CHART_COLORS_ARRAY } from '@/constants/ui';
// import { peerSupportRoutes } from '@/constants/routes/app';
import usePeerSupportDashboard from '@/hooks/peersupport/usePeerSupportDashboard';
import PeerSupportDashboardHistoricalChart from '@/modules/peer-support/PeerSupportDashboardHistoricalChart';
import PeerSupportNewOutreachModal from '@/modules/peer-support/PeerSupportNewOutreachModal';
import PeerSupportTimeline from '@/modules/peer-support/PeerSupportTimeline';
import colors from '@/theme/colors';
import { THealthDomain, THealthSubDomain } from '@/types/Health.types';
import { TPeerSupportEventOutcome } from '@/types/PeerSupport.types';
import { Center, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { createFileRoute } from '@tanstack/react-router';
import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

const ORG_LOGO_SIZE = 140;

const CHART_SECTION_HEIGHT = 220;
const CHART_SECTION_WIDTH = 360;
const SUBJECTS_CHART_SECTION_WIDTH = 380;

const SUBJECTS_CHART_DOMAINS_LIST: THealthDomain[] = [
  'physical',
  'mental',
  'social',
  'vocational',
  'spiritual',
  'purpose',
  'fun',
];

const SUBJECTS_CHART_COLORS = CHART_COLORS_ARRAY.slice(0, 7);

const PeerSupportDashboard = () => {
  // const navigate = useNavigate({ from: peerSupportRoutes.peerSupportDashboard });

  const [dashboardMonthData, setDashboardMonthData] = useState(
    `${new Date().getFullYear()}-${new Date().getMonth() + 1}`,
  );
  const [dashboardMonthDataToDisplay, setDashboardMonthDataToDisplay] = useState('');

  // just adds a `0` to the month part of the yyyy-mm date, if the month is less than 10
  useEffect(() => {
    const splitDashboardMonthDate = dashboardMonthData.split('-');
    const displayYear = splitDashboardMonthDate[0];

    const displayMonthNumber = parseInt(splitDashboardMonthDate[1]); // 1 - 12
    let displayMonthString = displayMonthNumber.toString();

    if (displayMonthNumber < 10) {
      displayMonthString = `0${displayMonthString}`;
    }

    const newDashboardMonthDataToDisplay = `${displayYear}-${displayMonthString}`;
    setDashboardMonthDataToDisplay(newDashboardMonthDataToDisplay);
  }, [dashboardMonthData]);

  const { data: peerSupportDashboard, isLoading, isRefetching } = usePeerSupportDashboard(dashboardMonthData);

  const totalOutreachesFromHistoricalData = useMemo(
    () => peerSupportDashboard?.historicalData.reduce((acc, curr) => (acc += curr.eventCount), 0),
    [peerSupportDashboard?.historicalData],
  );

  const [outreachModalOpen, setOutreachModalOpen] = useState(false);

  const [subjectsPerDomainChartList, subdomainsPerDomainChartData] = useMemo(() => {
    const subjectsList = Object.entries(peerSupportDashboard?.aggregates.subjects ?? {});

    const subjectsSortedPerDesign = [
      subjectsList.find((subj) => subj[0] === 'physical'),
      subjectsList.find((subj) => subj[0] === 'mental'),
      subjectsList.find((subj) => subj[0] === 'social'),
      subjectsList.find((subj) => subj[0] === 'vocational'),
      subjectsList.find((subj) => subj[0] === 'spiritual'),
      subjectsList.find((subj) => subj[0] === 'purpose'),
      subjectsList.find((subj) => subj[0] === 'fun'),
    ].filter((elem) => elem !== undefined);

    const domainsList: TChartDataItem[] = [];
    const subdomainsList: any = {};

    subjectsSortedPerDesign.forEach(([key, value]) => {
      domainsList.push({
        name: WELLNESS_DOMAINS_DISPLAY_LABELS[key as THealthDomain],
        value: value.count,
        description: 'Click to view Subdomains',
      });

      const keyDomainSubdomainsList: TChartDataItem[] = [];
      Object.entries(value.subdomains).forEach(([key, value]) => {
        keyDomainSubdomainsList.push({
          name: WELLNESS_SUB_DOMAINS_DISPLAY_LABELS[key as THealthSubDomain],
          value: value,
          description: `${WELLNESS_SUB_DOMAINS_DISPLAY_LABELS[key as THealthSubDomain]}: ${value}`,
        });
      });
      subdomainsList[key] = keyDomainSubdomainsList;
    });

    return [domainsList, subdomainsList];
  }, [peerSupportDashboard?.aggregates.subjects]);

  const subjectsPerDomainChartColors: TChartColorItem[] = useMemo(
    () =>
      SUBJECTS_CHART_COLORS.map((color, i) => ({
        name: subjectsPerDomainChartList?.[i]
          ? subjectsPerDomainChartList?.[i].name
          : WELLNESS_DOMAINS_DISPLAY_LABELS[SUBJECTS_CHART_DOMAINS_LIST[i] as THealthDomain],
        value: color,
      })),
    [subjectsPerDomainChartList],
  );

  // Section selection and subdomains modal and chart
  const [selectedChartSectionLabel, setSelectedChartSectionLabel] = useState('');
  const [subDomainModalOpen, setSubDomainModalOpen] = useState(false);

  const updateSelectedChartSection = (chartSectionLabel: string) => {
    setSelectedChartSectionLabel(chartSectionLabel);
    setSubDomainModalOpen(true);
  };

  const subdomainsChartData = useMemo(
    () => subdomainsPerDomainChartData[WELLNESS_DOMAINS_DISPLAY_LABELS_REVERSE[selectedChartSectionLabel]],
    [subdomainsPerDomainChartData, selectedChartSectionLabel],
  );

  const subdomainsChartColors: TChartColorItem[] = useMemo(() => {
    if (selectedChartSectionLabel) {
      const subdomainsToDisplay =
        subdomainsPerDomainChartData[WELLNESS_DOMAINS_DISPLAY_LABELS_REVERSE[selectedChartSectionLabel]];

      const subdomainChartColors = CHART_COLORS_ARRAY.slice(0, subdomainsToDisplay?.length);

      return subdomainChartColors.map((color, i) => {
        const subdomain = subdomainsToDisplay?.[i]?.name;

        return {
          name: subdomain,
          value: color,
        };
      });
    }

    return [];
  }, [subdomainsPerDomainChartData, selectedChartSectionLabel]);

  // Outcomes chart
  const outcomesChartData = useMemo(() => {
    const outcomes = peerSupportDashboard?.aggregates.outcomes ?? {};

    const outcomesList: TChartDataItem[] = [];

    Object.entries(outcomes).forEach(([key, value]) => {
      outcomesList.push({
        name: PEER_SUPPORT_EVENT_OUTCOME_DISPLAY_LABELS[key as TPeerSupportEventOutcome],
        value: value as number,
      });
    });

    return outcomesList;
  }, [peerSupportDashboard?.aggregates.outcomes]);

  const outcomesChartColors = useMemo(() => {
    const outcomesNames = Object.keys(peerSupportDashboard?.aggregates.outcomes ?? {});

    const chartColors = CHART_COLORS_ARRAY.slice(0, outcomesNames.length).map((color, i) => ({
      name: PEER_SUPPORT_EVENT_OUTCOME_DISPLAY_LABELS[outcomesNames[i] as TPeerSupportEventOutcome],
      value: color,
    }));

    return chartColors;
  }, [peerSupportDashboard?.aggregates.outcomes]);

  if (isLoading) return <LoadingPage />;

  return (
    <VStack width={MAX_CONTAINER_WIDTH} paddingBottom={'100px'}>
      <Text variant={'loraMediumTitle'} marginBottom={'110px'}>
        {peerSupportDashboard?.organizationName}'s Peer Support Outreach Report
      </Text>

      {/* Actual page content */}
      <VStack
        width={'100%'}
        borderRadius={'20px'}
        backgroundColor={'extra.white'}
        padding={'40px'}
        paddingTop={`${(ORG_LOGO_SIZE * 2) / 3}px`}
        position={'relative'}
        boxShadow={'0px 10px 80px 0px #00417926'}
      >
        {/* Org Logo */}
        <Center position="absolute" top={`-${ORG_LOGO_SIZE / 2}px`}>
          <VStack backgroundColor={'white'} borderRadius={'50%'} boxShadow="0px 10px 20px 0px #0E006224">
            <Image
              alt="Peer Support Dash"
              width={`${ORG_LOGO_SIZE}px`}
              height={`${ORG_LOGO_SIZE}px`}
              src={peerSupportDashboard?.logoUrl ?? iconsPng.handshake}
              style={{ borderRadius: '50%', objectFit: 'cover' }}
            />
          </VStack>
        </Center>

        <HStack justifyContent={'space-between'} width={'100%'} marginBottom={'50px'}>
          <VStack alignItems={'flex-start'}>
            <Text variant="loraSmallTitle" textAlign={'start'} fontWeight={500}>
              Engagement Data
            </Text>

            <Text variant="urbanistExtraBold" textAlign={'start'} color={'text.mediumGray'}>
              {format(!!dashboardMonthDataToDisplay ? new Date(dashboardMonthDataToDisplay) : new Date(), 'MMM yyyy')}
            </Text>
          </VStack>

          <HStack gap={'20px'}>
            {isRefetching && (
              <LoadingLogo logoHeight="54px" logoWidth="54px" logoVariant={logoVariants.purpleNoText160x160} />
            )}
            <CustomButton
              backgroundColor="primary.500"
              labelColor="extra.white"
              label="New Outreach"
              iconBeforeLabelJSX={
                <HStack width={'100%'} position={'absolute'} right={'20px'} justifyContent={'flex-end'}>
                  <SvgIcon iconPath={svgIcons.rightArrow} color={colors.extra.white} size={'14'} />
                </HStack>
              }
              labelStyle={{ marginRight: '22px' }}
              width={'170px'}
              onClick={() => setOutreachModalOpen(true)}
            />
          </HStack>
        </HStack>

        <HStack width={'100%'} justifyContent={'space-between'} height={`${CHART_SECTION_HEIGHT}px`}>
          <VStack width={`${CHART_SECTION_WIDTH}px`} justifyContent={'start'} alignItems={'start'} height={'100%'}>
            <ChartSectionTitle
              title={'Outreaches'}
              additionalText={`${totalOutreachesFromHistoricalData ?? ''} total`}
            />
            <VStack>
              <PeerSupportDashboardHistoricalChart
                data={peerSupportDashboard?.historicalData ?? []}
                onBarClick={(month) => setDashboardMonthData(month)}
                selectedMonth={dashboardMonthData}
              />
            </VStack>
          </VStack>

          <VStack width={`${SUBJECTS_CHART_SECTION_WIDTH}px`} height={'100%'}>
            <ChartSectionTitle title="Subjects" />
            <VStack align={'start'} width={'100%'}>
              <DoughnutChart
                data={subjectsPerDomainChartList}
                colors={subjectsPerDomainChartColors}
                chartSvgWidth={160}
                onChartSectionClick={(section: string) => updateSelectedChartSection(section)}
                hasShadow={false}
                chartPadding={0}
                chartShadowPadding={10}
                chartAndLegendGap="20px"
                legendItemsVerticalGap="5px"
                legendDotAndTextSize="12px"
                hoverCorrection={-50}
                hoverTooltipSide="left"
                tooltipElementConfig={{ hasDescription: true, width: '190px' }}
                legendLabelIncludesValue
              />
            </VStack>
          </VStack>

          <VStack width={`${CHART_SECTION_WIDTH}px`} height={'100%'}>
            <ChartSectionTitle title="Outcomes" />
            <VStack align={'start'} width={'100%'}>
              <DoughnutChart
                additionalSvgId="outcomes"
                data={outcomesChartData}
                colors={outcomesChartColors}
                chartSvgWidth={160}
                hasShadow={false}
                chartPadding={0}
                chartShadowPadding={10}
                chartAndLegendGap="20px"
                legendItemsVerticalGap="5px"
                legendDotAndTextSize="12px"
                hoverCorrection={-50}
                tooltipElementConfig={{}}
                legendLabelIncludesValue
              />
            </VStack>
          </VStack>
        </HStack>

        <PeerSupportTimeline timelineEvents={peerSupportDashboard?.events ?? []} />
      </VStack>

      <PeerSupportNewOutreachModal isOpen={outreachModalOpen} setIsOpen={setOutreachModalOpen} />

      {/* Subdomain modal */}
      <ModalContainer
        isOpen={subDomainModalOpen}
        onClose={() => setSubDomainModalOpen(false)}
        isBlur={false}
        width="55vw"
        height="40vh"
        backgroundColor={colors.background.lightBlue}
      >
        <VStack padding={'20px'} gap={'10px'}>
          <Text variant={'loraSmallTitle'} marginBottom={'30px'} fontWeight={500}>
            {selectedChartSectionLabel} Wholeness Subdomains
          </Text>
          <DoughnutChart
            additionalSvgId="subdomains"
            data={subdomainsChartData}
            colors={subdomainsChartColors}
            chartSvgWidth={300}
            legendItemsVerticalGap="12px"
            legendDotAndTextSize="18px"
            tooltipElementConfig={{}} // makes the tooltip not display, while keeping the hover behavior
            legendLabelIncludesValue
          />
        </VStack>
      </ModalContainer>
    </VStack>
  );
};

export const Route = createFileRoute('/_authenticated/_peer-support/peer-support/dashboard')({
  component: PeerSupportDashboard,
});

const ChartSectionTitle = ({ title, additionalText }: { title: string; additionalText?: string }) => {
  return (
    <HStack width={'100%'} height={'100%'} justifyContent={'flex-start'} alignItems={'flex-start'} gap={'5px'}>
      <Text variant={'urbanistBold'} color={'text.darkBlue'}>
        {title}
      </Text>
      <Text variant={'urbanistBold'} color={'text.mediumGray'}>
        {additionalText}
      </Text>
    </HStack>
  );
};
