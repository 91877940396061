import svgIcons from '@/assets/svg/icons';
import { Center, Image, Text, Tooltip, VStack } from '@chakra-ui/react';
import React from 'react';
import SvgIcon from '../SvgIcon';

interface CardHeaderWithImageAndNameProps {
  onClick?: () => void;
  hasTooltip?: boolean;
  profilePicture?: string;
  name: string;
  nameSubtitle?: string;
}

const CardHeaderWithImageAndName = ({
  onClick,
  hasTooltip,
  profilePicture,
  name,
  nameSubtitle,
}: CardHeaderWithImageAndNameProps) => {
  return (
    <VStack
      width="100%"
      paddingBottom={'20px'}
      gap={'20px'}
      cursor={'pointer'}
      data-group
      onClick={onClick}
      position={'relative'}
    >
      <Tooltip
        label={'Click to view profile'}
        visibility={hasTooltip ? 'visible' : 'hidden'}
        padding={'14px'}
        borderRadius={'6px'}
        color={'text.darkBlue'}
        backgroundColor={'extra.white'}
        boxShadow={'0px 2px 8px 0px #00417933'}
        placement="right"
        top={-14}
        right={70}
      >
        <VStack width={'100%'}>
          {/* Profile Pic */}
          <VStack
            maxWidth={'100px'}
            maxHeight={'100px'}
            borderWidth={'4px'}
            borderRadius={'full'}
            borderColor={'white'}
            shadow={'0px 6px 24px 0px rgba(14, 0, 98, 0.2)'}
            overflow={'hidden'}
            cursor={'pointer'}
            _groupHover={{ boxShadow: '0px 4px 8px 0px rgba(14, 0, 98, 0.2)' }}
            transition={'box-shadow 0.1s'}
            position={'absolute'}
            top={-70}
          >
            {profilePicture ? (
              <Image
                src={profilePicture}
                alt={name}
                width={100}
                height={100}
                borderRadius={'full'}
                objectFit={'cover'}
                backgroundColor={'white'}
              />
            ) : (
              <Center backgroundColor={'#EDE8FD'} height={100} width={100}>
                <SvgIcon color={'#fff'} iconPath={svgIcons.userSeeThrough} size={60} boxHeight={80} boxWidth={80} />
              </Center>
            )}
          </VStack>
          {/* Support role and name */}
          <VStack paddingTop={'50px'} gap={'6px'} width={'100%'}>
            <Text
              variant={'lora'}
              fontSize={'22px'}
              lineHeight={'22px'}
              fontWeight={500}
              color={'text.darkBlue'}
              fontStyle={'italic'}
              noOfLines={2}
              maxWidth={'250px'}
              textAlign={'center'}
            >
              {name}
            </Text>
            {nameSubtitle && (
              <Text variant={'urbanistBold'} color={'text.mediumGray'}>
                {nameSubtitle}
              </Text>
            )}
          </VStack>
        </VStack>
      </Tooltip>
    </VStack>
  );
};

export default CardHeaderWithImageAndName;
